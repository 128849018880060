@import "../../../assets/scss/init";

@media (min-width:#{$tablet_max+1}px) {
    .container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        overflow: hidden;
        background: #000;

        video {
            width: 100vw;
            height: 100%;
            object-fit: cover;
            opacity: 0.75;
        }
    }
}

@media (min-width:#{$mobile_max+1}px)  and (max-width:#{$tablet_max}px) {
    .container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        overflow-x: hidden;
        background: #000;

        video {
            width: 100vw;
            height: 100%;
            object-fit: cover;
            opacity: 0.75;
        }
    }
}

@media (max-width:#{$mobile_max}px) {
    .container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        overflow-x: hidden;
        background: #000;

        video {
            width: 100vw;
            height: 100%;
            object-fit: cover;
            opacity: 0.75;
        }
    }
}
