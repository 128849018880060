@import "../../../assets/scss/init";
.title {
	text-transform: uppercase;
	font-family: SegoeUI;
	font-size: 24px;
	font-weight: $normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: #202124;
	margin-bottom: 16px;
}

.totals {
	&-col {
		width: 33%;
		max-width: 300px;
		margin-right: 40px;
	}

	&-main {
		display: flex;
		align-items: flex-end;
	}

	&-rate {
		font-family: SegoeUI;
		font-size: 50px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1;
		letter-spacing: normal;
		text-align: left;
		color: currentColor;
		margin-right: 10px;
	}
	&-title {
		font-family: SegoeUI;
		font-size: 16px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		margin-bottom: 6px;
		letter-spacing: normal;
		text-align: left;
		color: #000000;
	}

	&-quantity {
		color: #000000;
		margin-bottom: 3px;
		font-size: 13px;
		font-weight: normal;
	}

	&-items {
		list-style-type: none;
		margin-top: 16px;
	}

	&-item {
		display: block;
		margin-bottom: 24px;
	}

	&-item-text {
		margin-bottom: 2px;
		display: flex;
		justify-content: space-between;
		width: 100%;
		font-family: SegoeUI;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: normal;
		text-align: left;
		color: #707070;
	}
	&-item-gauge {
		width: 100%;
		height: 10px;
		border-radius: 25px;
		background-color: #e4e4e4;
		span {
			transition: width 1s;
			border-radius: 25px;
			height: 100%;
			display: block;
			background-color: currentColor;
		}
	}
}

@media (max-width:#{$mobile_max}px) {
	.totals {
		&-col {
			width: 100%;
			max-width: 100%;
			margin-right: 00px;
			order: 2;
		}

		&-main {
			display: flex;
			align-items: flex-end;
		}

		&-rate {
			font-family: SegoeUI;
			font-size: 50px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
			text-align: left;
			color: currentColor;
			margin-right: 10px;
		}
		&-title {
			font-family: SegoeUI;
			font-size: 16px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			margin-bottom: 6px;
			letter-spacing: normal;
			text-align: left;
			color: #000000;
		}

		&-quantity {
			color: #000000;
			margin-bottom: 3px;
			font-size: 13px;
			font-weight: normal;
		}

		&-items {
			list-style-type: none;
			margin-top: 16px;
		}

		&-item {
			display: block;
			margin-bottom: 12px;
		}

		&-item-text {
			margin-bottom: 2px;
			display: flex;
			justify-content: space-between;
			width: 100%;
			font-family: SegoeUI;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 3.17;
			letter-spacing: normal;
			text-align: left;
			color: #707070;
		}
		&-item-gauge {
			width: 100%;
			height: 10px;
			border-radius: 25px;
			background-color: #e4e4e4;
			span {
				transition: width 1s;
				border-radius: 25px;
				height: 100%;
				display: block;
				background-color: currentColor;
			}
		}
	}
}
