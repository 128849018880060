.displayBlock {
	transform: translateX(30px);
	transition: 0.5s;
	position: absolute;
	top: -28px;
	left: 6px;
	z-index: 1;
	background: #f5f5f5;
	border-radius: 6px;
	overflow: hidden;
	padding: 3px 0px;
	opacity: 1;
	width: auto;
	li {
		button {
			text-align: center;
			display: block;
			padding: 3px 8px !important;
			color: #c8cdd5 !important;
			text-decoration: none;
			font-size: 16px;
			outline: none;
			&:hover {
				color: currentColor !important;
			}
		}
	}
}
.item-socials {
	transition: transform 1s linear;
	transform: translateX(-50px);
	position: absolute;
	top: -24px;
	left: 6px;
	z-index: 1;
	background: #f5f5f5;
	border-radius: 6px;
	overflow: hidden;
	padding: 3px 0px;
	opacity: 0;
	width: 0px;
	li {
		button {
			text-align: center;
			display: block;
			padding: 3px 8px !important;
			color: #c8cdd5 !important;
			text-decoration: none;
			font-size: 16px;
			outline: none;
		}
	}
}

.item {
	&-icon {
		margin-right: 5px;
		width: 25px;
		height: 25px;
		background-color: #c8cdd5;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		margin-bottom: 5px;
		cursor: pointer;
		transition: background-color 0.5s;
		span,
		svg {
			font-size: 15px;
			color: #fff;
			font-weight: bold;
			line-height: 1;
		}
		&:hover {
			box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
			transition: 0.3s;
			background-color: currentColor;
			transform: scale(1.2);
		}
	}
}
