@import "../../assets/scss/init";

@media (min-width:#{$tablet_max+1}px) {
	.rightLink {
		text-decoration: none;
		position: absolute;
		z-index: 5;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}
	.offerButton {
		z-index: 15;
	}
	.container {
		display: flex;
		width: 100%;
		overflow-x: hidden;
		flex-flow: row;
		height: 600px;
	}

	.main {
		height: 100%;
		min-width: 500px;
		max-width: 1160px;
		flex-grow: 1;
		background-size: cover;
		background-position: center center;
		display: flex;
		align-items: center;
		justify-content: center;
		background-size: cover;
		position: relative;
	}

	.panel-right {
		height: 100%;
		width: calc(50vw - 580px);
		min-width: 260px;
		overflow: hidden;
	}

	.panel-bg {
		background-size: cover;
		position: absolute;
		width: calc(100% + 38px);
		height: calc(100% + 38px);
		top: -19px;
		left: -19px;
		filter: blur(19px);
	}

	.offers {
		padding-top: 30px;
	}
	.offerBtn {
		height: 36px !important;
	}

	.offer {
		display: block;
		margin: 10px auto;
		width: 254px;
		height: 160px;
		background-size: cover;
		border-radius: 6px;
		overflow: hidden;
		transition: 0.3s;
		&:hover {
			transition: 0.3s;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
			// div {
			.offer-title {
				color: currentColor;
			}
			// }
		}
		&-data {
			background-color: #fff;
			box-sizing: border-box;
			margin-left: 93px;
			padding: 8px 5px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			height: 100%;
		}
		&-title {
			font-family: SegoeUI;
			font-size: 12px;
			font-weight: $semi-bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			text-align: left;
			color: #4e5255;
			margin-top: 10px;
		}
		&-label {
			display: inline-block;
			box-sizing: border-box;
			margin: 0 1px;
			font-family: SegoeUI;
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
			text-align: left;
			color: #ffffff;
			border-radius: 3px;
			background-color: #66a3e9;
			padding: 3px 5px;
		}

		&-price {
			span {
				vertical-align: top;
				font-family: SegoeUI;
				font-size: 20px;
				font-weight: $semi-bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.35;
				letter-spacing: normal;
				text-align: left;
				color: currentColor;
			}
			i {
				position: relative;
				font-size: 25px;
				color: #f44336;
				&:after {
					font-family: sans-serif;
					font-size: 16px;
					font-weight: bold;
					color: #ffffff;
					display: block;
					line-height: 1;
					position: absolute;
					top: 4px;
					left: 5px;
					content: "%";
				}
			}
		}

		&-btn {
			background-color: #fff;
			display: inline-block;
			box-sizing: border-box;
			color: currentColor;
			outline: none;
			border: 1px solid currentColor;
			padding: 6px 8px;
			border-radius: 3px;
			text-transform: uppercase;
			font-family: SegoeUI;
			font-size: 11px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			text-align: center;
			cursor: pointer;
			transition: 0.3s;

			&:hover {
				transition: 0.3s;
				background-color: currentColor;
				color: #fff;
				span,
				i {
					transition: 0.3s;
					color: #fff;
				}
			}

			&-icon {
				background-color: currentColor;
				padding: 2px 3px;
				top: 4px;
				position: relative;
				font-size: 20px;
				line-height: 1;
				i,
				span {
					color: #fff;
				}

				&:hover {
					background-color: #fff;
					i,
					span {
						color: currentColor;
					}
				}
			}
		}
	}
}
@media (max-width: 1280px) {
	.panel-right {
		height: 100%;
		width: calc(50vw - 580px);
		min-width: 260px;
		overflow: hidden;
		display: none;
	}
}
@media (max-width:#{$mobile_max}px) {
	.rightLink {
		text-decoration: none;
		position: absolute;
		z-index: 5;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}
	.offerButton {
		z-index: 15;
	}
	.container {
		display: flex;
		flex-flow: column;
		height: calc(100vh - 100px);
	}

	.main {
		height: 100%;
		min-width: 100%;
		max-width: 100%;
		flex-grow: 1;
		background-size: cover;
		background-position: center center;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.panel-right {
		height: 100%;
		width: calc(50vw - 580px);
		min-width: 260px;
		overflow: hidden;
		display: none;
	}

	.panel-bg {
		background-size: cover;
		position: absolute;
		width: calc(100% + 38px);
		height: calc(100% + 38px);
		top: -19px;
		left: -19px;
		filter: blur(19px);
	}

	.offers {
		padding-top: 30px;
	}
	.offerBtn {
		height: 36px !important;
	}
	.offer {
		display: block;
		margin: 10px auto;
		width: 254px;
		height: 160px;
		background-size: cover;
		border-radius: 6px;
		overflow: hidden;
		transition: 0.3s;
		&:hover {
			transition: 0.3s;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
			.offer-title {
				color: currentColor;
			}
		}
		&-data {
			background-color: #fff;
			box-sizing: border-box;
			margin-left: 93px;
			padding: 8px 5px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			height: 100%;
		}
		&-title {
			font-family: SegoeUI;
			font-size: 12px;
			font-weight: $semi-bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			text-align: left;
			color: #4e5255;
			margin-top: 10px;
		}
		&-label {
			display: inline-block;
			box-sizing: border-box;
			margin: 0 1px;
			font-family: SegoeUI;
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
			text-align: left;
			color: #ffffff;
			border-radius: 3px;
			background-color: #66a3e9;
			padding: 3px 5px;
		}

		&-price {
			span {
				vertical-align: top;
				font-family: SegoeUI;
				font-size: 20px;
				font-weight: $semi-bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.35;
				letter-spacing: normal;
				text-align: left;
				color: currentColor;
			}
			i,
			svg {
				font-size: 25px;
				color: #f44336;
			}
		}

		&-btn {
			background-color: #fff;
			display: inline-block;
			box-sizing: border-box;
			color: currentColor;
			outline: none;
			border: 1px solid currentColor;
			padding: 6px 8px;
			border-radius: 3px;
			text-transform: uppercase;
			font-family: SegoeUI;
			font-size: 11px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			text-align: center;
			cursor: pointer;
			transition: 0.3s;

			&:hover {
				transition: 0.3s;
				background-color: currentColor;
				color: #fff;
				span,
				i {
					transition: 0.3s;
					color: #fff;
				}
			}

			&-icon {
				background-color: currentColor;
				padding: 2px 3px;
				top: 4px;
				position: relative;
				font-size: 20px;
				line-height: 1;
				i,
				span {
					color: #fff;
				}

				&:hover {
					background-color: #fff;
					i,
					span {
						color: currentColor;
					}
				}
			}
		}
	}
}

@media (min-width:#{$mobile_max+1}px)  and (max-width:#{$tablet_max}px) {
	.rightLink {
		text-decoration: none;
		position: absolute;
		z-index: 5;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}
	.offerButton {
		z-index: 15;
	}
	.container {
		display: flex;
		flex-flow: row;
		height: 600px;
	}

	.main {
		height: 100%;
		flex-grow: 1;
		background-size: cover;
		background-position: center center;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.panel-right {
		height: 100%;
		width: 320px;

		overflow: hidden;
	}

	.panel-bg {
		background-size: none;
		position: absolute;
		width: calc(100% + 38px);
		height: calc(100% + 38px);
		top: -19px;
		left: -19px;
		filter: blur(19px);
	}

	.offers {
		padding-top: 30px;
	}
	.offerBtn {
		height: 36px !important;
	}

	.offer {
		display: block;
		margin: 10px auto;
		width: 254px;
		height: 160px;
		background-size: cover;
		border-radius: 6px;
		overflow: hidden;
		transition: 0.3s;
		&:hover {
			transition: 0.3s;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
			.offer-title {
				color: currentColor;
			}
		}
		&-data {
			background-color: #fff;
			box-sizing: border-box;
			margin-left: 93px;
			padding: 8px 5px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			height: 100%;
		}
		&-title {
			font-family: SegoeUI;
			font-size: 12px;
			font-weight: $semi-bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			text-align: left;
			color: #4e5255;
			margin-top: 10px;
		}
		&-label {
			display: inline-block;
			box-sizing: border-box;
			margin: 0 1px;
			font-family: SegoeUI;
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
			text-align: left;
			color: #ffffff;
			border-radius: 3px;
			background-color: #66a3e9;
			padding: 3px 5px;
		}

		&-price {
			span {
				vertical-align: top;
				font-family: SegoeUI;
				font-size: 20px;
				font-weight: $semi-bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.35;
				letter-spacing: normal;
				text-align: left;
				color: currentColor;
			}
			i,
			svg {
				font-size: 25px;
				color: #f44336;
			}
		}

		&-btn {
			background-color: #fff;
			display: inline-block;
			box-sizing: border-box;
			color: currentColor;
			outline: none;
			border: 1px solid currentColor;
			padding: 6px 8px;
			border-radius: 3px;
			text-transform: uppercase;
			font-family: SegoeUI;
			font-size: 11px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			text-align: center;
			cursor: pointer;
			transition: 0.3s;

			&:hover {
				transition: 0.3s;
				background-color: currentColor;
				color: #fff;
				span,
				i {
					transition: 0.3s;
					color: #fff;
				}
			}

			&-icon {
				background-color: currentColor;
				padding: 2px 3px;
				top: 4px;
				position: relative;
				font-size: 20px;
				line-height: 1;
				i,
				span {
					color: #fff;
				}

				&:hover {
					background-color: #fff;
					i,
					span {
						color: currentColor;
					}
				}
			}
		}
	}
}
