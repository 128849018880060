@import "../../../assets/scss/init";

@media (min-width:#{$tablet_max+1}px) {
    .forTablet {
        display: none;
    }
    .panel-left {
        height: 100%;
        width: calc(50vw - 580px);

        min-width: 260px;
        box-sizing: border-box;
        padding-top: 30px;
        display: flex;
        align-items: center;
        flex-direction: column;
        &--solid {
            background: #fff;
        }
    }
    .categories {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        user-select: none;
        height: 100%;
    }

    .heading {
        font-family: SegoeUI;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.83;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
        margin-bottom: 18px;
    }
    .location {
        i {
            position: relative;
            transition: 0.3s;
            top: 0;
            margin-right: 6px;
        }

        a {
            display: inline-block;
            border-bottom: 1px dashed currentColor;
            text-decoration: none;
            font-family: SegoeUI;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: normal;
            text-align: left;
            color: #000000;
            margin-bottom: 24px;
        }

        &:hover {
            i {
                transition: 0.3s;
                top: -3px;
            }
        }
    }

    .category {
        &-icon {
            width: 30px;
            height: 30px;
            background-size: contain;
            background-repeat: no-repeat;
        }

        label {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 5px;
            padding: 5px;
            background: rgba(255, 255, 255, 0.75);
            border: 1px solid currentColor;
            border-radius: 6px;
            width: 110px;
            box-sizing: border-box;
            height: 80px;
            cursor: pointer;
            transition: 0.5s;
            &:hover {
                transition: 0.5s;
                background: #fff;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
            }

            i {
                color: #000;
                font-size: 24px;
            }

            p {
                color: #000;
                font-family: SegoeUI;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                letter-spacing: normal;
                text-align: center;
                color: #000000;
            }
        }
        input {
            position: absolute;
            visibility: hidden;
        }

        input:checked + label {
            background-color: currentColor;
            .category-icon {
                filter: invert(100%);
            }
            i,
            p {
                color: #fff;
            }
        }
    }
}

@media (max-width:#{$mobile_max}px) {
    .forTablet {
        display: none;
    }
    .moveCategories {
        display: none;
    }
    .panel-left {
        height: 40px;
        width: auto;
        background: transparentize(#fff, 0.8);
        min-width: 0;
        box-sizing: border-box;
        padding: 12px 20px 40px;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 15px;
        z-index: 1;
        border-radius: 0 0 6px 6px;
    }

    .categories {
        display: none;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        user-select: none;
        height: 100%;
    }

    .heading {
        display: none;
        font-family: SegoeUI;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.83;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
        margin-bottom: 18px;
    }
    .location {
        i {
            position: relative;
            transition: 0.3s;
            top: 0;
            margin-right: 6px;
        }

        a {
            display: inline-block;
            border-bottom: 1px dashed currentColor;
            text-decoration: none;
            font-family: SegoeUI;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: normal;
            text-align: left;
            color: #000000;
            margin-bottom: 24px;
        }

        &:hover {
            i {
                transition: 0.3s;
                top: -3px;
            }
        }
    }

    .categories {
        display: none;
    }

    .category {
        &-icon {
            width: 24px;
            height: 24px;
            background-size: contain;
            background-repeat: no-repeat;
        }
        label {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 5px;
            padding: 5px;
            background: #fff;
            border: 1px solid currentColor;
            border-radius: 6px;
            width: 110px;
            box-sizing: border-box;
            height: 80px;
            cursor: pointer;
            transition: 0.5s;
            &:hover {
                transition: 0.5s;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
            }

            i {
                color: #000;
                font-size: 24px;
            }

            p {
                color: #000;
                font-family: SegoeUI;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                letter-spacing: normal;
                text-align: center;
                color: #000000;
            }
        }
        input {
            position: absolute;
            visibility: hidden;
        }

        input:checked + label {
            background-color: currentColor;
            .category-icon {
                filter: invert(100%);
            }
            i,
            p {
                color: #fff;
            }
        }
    }
}

@media (min-width:#{$mobile_max+1}px)  and (max-width:#{$tablet_max}px) {
    .moveCategories {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        user-select: none;
        height: 100%;
        transform: translateY(-76px);
        transition-property: transform;
        transition-duration: 2s;
    }
    .panel-left {
        height: 100%;
        width: 144px;
        background: #fff;
        box-sizing: border-box;
        padding-top: 30px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .categories {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        user-select: none;
        height: 100%;
        transform: translateY(0px);
        transition-property: transform;
        transition-duration: 2s;
    }

    .heading {
        font-family: SegoeUI;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.83;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
        margin-bottom: 18px;
    }

    form {
        height: 80%;
        overflow-x: hidden;
    }
    .forTablet {
        display: block;
        margin: 0 auto;
    }

    .location {
        i {
            position: relative;
            transition: 0.3s;
            top: 0;
            margin-right: 6px;
        }

        a {
            display: inline-block;
            border-bottom: 1px dashed currentColor;
            text-decoration: none;
            font-family: SegoeUI;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: normal;
            text-align: left;
            color: #000000;
            margin-bottom: 24px;
        }

        &:hover {
            i {
                transition: 0.3s;
                top: -3px;
            }
        }
    }

    .category {
        &-icon {
            width: 24px;
            height: 24px;
            background-size: contain;
            background-repeat: no-repeat;
        }
        label {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 5px;
            padding: 5px;
            background: #fff;
            border: 1px solid currentColor;
            border-radius: 6px;
            width: 96px;
            box-sizing: border-box;
            height: 80px;
            cursor: pointer;
            transition: 0.5s;
            &:hover {
                transition: 0.5s;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
            }

            i {
                color: #000;
                font-size: 24px;
            }

            p {
                color: #000;
                font-family: SegoeUI;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                letter-spacing: normal;
                text-align: center;
                color: #000000;
            }
        }
        input {
            position: absolute;
            visibility: hidden;
        }

        input:checked + label {
            background-color: currentColor;
            .category-icon {
                filter: invert(100%);
            }
            i,
            p {
                color: #fff;
            }
        }
    }
}

//Hack

@media (min-width: 768px) and (max-width: 1023px) {
    .category {
        width: 100%;
        &-icon {
            width: 24px;
            height: 24px;
            background-size: contain;
            background-repeat: no-repeat;
        }
        label {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 5px;
            padding: 5px;
            background: #fff;
            border: 1px solid currentColor;
            border-radius: 6px;
            width: auto;
            box-sizing: border-box;
            height: auto;
            cursor: pointer;
            transition: 0.5s;
            &:hover {
                transition: 0.5s;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
            }

            i {
                color: #000;
                font-size: 16px;
            }

            p {
                color: #000;
                font-family: SegoeUI;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                letter-spacing: normal;
                text-align: center;
                color: #000000;
            }
        }
        input {
            position: absolute;
            visibility: hidden;
        }

        input:checked + label {
            background-color: currentColor;
            .category-icon {
                filter: invert(100%);
            }
            i,
            p {
                color: #fff;
            }
        }
    }
}
