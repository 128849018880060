/* ----------------------------------------------
 * Generated by Animista on 2019-10-23 15:49:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */


/**
 * ----------------------------------------
 * animation flip-2-hor-bottom-1
 * ----------------------------------------
 */
%flip-2-hor-bottom-1 {
	-webkit-animation: flip-2-hor-bottom-1 1.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both reverse;
	        animation: flip-2-hor-bottom-1 1.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both reverse;
}

 @-webkit-keyframes flip-2-hor-bottom-1 {
    0% {
      -webkit-transform: translateY(0) rotateX(0);
              transform: translateY(0) rotateX(0);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
        
    }
    100% {
      -webkit-transform: translateY(100%) rotateX(180deg);
              transform: translateY(100%) rotateX(180deg);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
        
    }
  }
  @keyframes flip-2-hor-bottom-1 {
    0% {
      -webkit-transform: translateY(0) rotateX(0);
              transform: translateY(0) rotateX(0);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
    100% {
      -webkit-transform: translateY(100%) rotateX(180deg);
              transform: translateY(100%) rotateX(180deg);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
    }
  }
  
  /* ----------------------------------------------
 * Generated by Animista on 2019-10-23 16:4:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-2-hor-top-bck
 * ----------------------------------------
 */
%flip-2-hor-top-bck {
	-webkit-animation: flip-2-hor-top-bck 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: flip-2-hor-top-bck 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}
@-webkit-keyframes flip-2-hor-top-bck {
        0% {
          -webkit-transform: translateY(0) translateZ(0) rotateX(0);
                  transform: translateY(0) translateZ(0) rotateX(0);
          -webkit-transform-origin: 50% 0%;
                  transform-origin: 50% 0%;
        }
        100% {
          -webkit-transform: translateY(-100%) translateZ(-260px) rotateX(180deg);
                  transform: translateY(-100%) translateZ(-260px) rotateX(180deg);
          -webkit-transform-origin: 50% 100%;
                  transform-origin: 50% 100%;
        }
      }
      @keyframes flip-2-hor-top-bck {
        0% {
          -webkit-transform: translateY(0) translateZ(0) rotateX(0);
                  transform: translateY(0) translateZ(0) rotateX(0);
          -webkit-transform-origin: 50% 0%;
                  transform-origin: 50% 0%;
        }
        100% {
          -webkit-transform: translateY(-100%) translateZ(-260px) rotateX(180deg);
                  transform: translateY(-100%) translateZ(-260px) rotateX(180deg);
          -webkit-transform-origin: 50% 100%;
                  transform-origin: 50% 100%;
        }
      }
      

      /* ----------------------------------------------
 * Generated by Animista on 2019-10-23 16:8:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-2-ver-left-1
 * ----------------------------------------
 */
 %flip-2-ver-left-1 {
	-webkit-animation: flip-2-ver-left-1 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both reverse;
	        animation: flip-2-ver-left-1 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both reverse;
}
@-webkit-keyframes flip-2-ver-left-1 {
        0% {
          -webkit-transform: translateX(0) rotateY(0);
                  transform: translateX(0) rotateY(0);
          -webkit-transform-origin: 0% 50%;
                  transform-origin: 0% 50%;
        }
        100% {
          -webkit-transform: translateX(-100%) rotateY(180deg);
                  transform: translateX(-100%) rotateY(180deg);
          -webkit-transform-origin: 100% 0%;
                  transform-origin: 100% 0%;
        }
      }
      @keyframes flip-2-ver-left-1 {
        0% {
          -webkit-transform: translateX(0) rotateY(0);
                  transform: translateX(0) rotateY(0);
          -webkit-transform-origin: 0% 50%;
                  transform-origin: 0% 50%;
        }
        100% {
          -webkit-transform: translateX(-100%) rotateY(180deg);
                  transform: translateX(-100%) rotateY(180deg);
          -webkit-transform-origin: 100% 0%;
                  transform-origin: 100% 0%;
        }
      }
      