.ut {
	&-section__container {
		margin: 50px auto 0;
		max-width: 1160px;
		box-sizing: border-box;
	}

	&-section__title {
		font-family: SegoeUI;
		font-size: 24px;
		font-weight: $semi-bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: normal;
		text-align: left;
		color: #202124;
		text-transform: uppercase;
		margin-right: 25px;
		text-decoration: none;
		padding-bottom: 5px;
		transition: 0.3s;
		border-bottom: 1px solid rgba(0, 0, 0, 0);
		display: inline-block;

		&:hover {
			color: currentColor;
			padding-bottom: 5px;
			transition: 0.3s;
		}
		&-link {
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
			text-transform: none;
			color: #58b5e3;
			margin-left: 12px;
			display: inline-flex;
			align-items: center;
			text-decoration: none;
			span {
				margin-right: 8px;
			}
		}
	}
	&-share {
		circle,
		rect {
			fill: #c8cdd5;
		}
		&:hover {
			circle,
			rect {
				color: inherit;
				fill: currentColor;
			}
		}
	}

	&-material-icon {
		color: currentColor;
	}
}

@media (max-width:#{$tablet_max}px) {
	.ut {
		&-section__container {
			margin: 50px 12px;
			max-width: auto;
			box-sizing: border-box;
			&--no-margin {
				margin: 0px 12px;
			}
		}
	}
}
