@import "../../assets/scss/init";

.headers {
  // background:#EDEDED;
  // border-left:6px solid #ededed;
  // border-right:6px solid #ededed;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.header {
  display: inline-block;
  box-sizing: border-box;
  background: #fff;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: $normal;
  text-align: center;
  cursor: pointer;

  // border-bottom:3px solid #FFF;
  // border-top:6px solid #EDEDED;
  span {
    color: #999;
  }
  &--active {
    font-weight: $medium;
    span {
      color: #000;
    }
    // border-top:6px solid rgba(0,0,0,0);
    border-bottom: 3px solid currentColor;
  }
}

.content {
  background: #fff;
  position: relative;
  // min-width: 40vw;
  // min-height: 40vh;
}

/* .tab {
    opacity:0;   
    position: relative;
    top:0;
    left:0;
    &--active {
        
        
        opacity:1;
    }
} */
