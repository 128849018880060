@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?7926oa');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?7926oa#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?7926oa') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?7926oa') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?7926oa##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ut-ico-"], [class*=" ut-ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ut-ico-more {
  &:before {
    content: $ut-ico-more; 
  }
}
.ut-ico-cart {
  &:before {
    content: $ut-ico-cart; 
  }
}
.ut-ico-calendar {
  &:before {
    content: $ut-ico-calendar; 
  }
}
.ut-ico-star-full {
  &:before {
    content: $ut-ico-star-full; 
  }
}
.ut-ico-rate {
  &:before {
    content: $ut-ico-rate; 
  }
}
.ut-ico-star {
  &:before {
    content: $ut-ico-star; 
  }
}
.ut-ico-favorite {
  &:before {
    content: $ut-ico-favorite; 
  }
}
.ut-ico-bookmark {
  &:before {
    content: $ut-ico-bookmark; 
  }
}
.ut-ico-search {
  &:before {
    content: $ut-ico-search; 
  }
}
.ut-ico-magnifier {
  &:before {
    content: $ut-ico-magnifier; 
  }
}
.ut-ico-magnifying-glass {
  &:before {
    content: $ut-ico-magnifying-glass; 
  }
}
.ut-ico-inspect {
  &:before {
    content: $ut-ico-inspect; 
  }
}
.ut-ico-find {
  &:before {
    content: $ut-ico-find; 
  }
}
.ut-ico-sales {
  &:before {
    content: $ut-ico-sales; 
  }
}
.ut-ico-home {
  &:before {
    content: $ut-ico-home; 
  }
}
.ut-ico-guest {
  &:before {
    content: $ut-ico-guest; 
  }
}
.ut-ico-clock {
  &:before {
    content: $ut-ico-clock; 
  }
}
.ut-ico-calendar2 {
  &:before {
    content: $ut-ico-calendar2; 
  }
}
.ut-ico-calendar1 {
  &:before {
    content: $ut-ico-calendar1; 
  }
}
.ut-ico-2 {
  &:before {
    content: $ut-ico-2; 
  }
}
.ut-ico-18 {
  &:before {
    content: $ut-ico-18; 
  }
}
.ut-ico-ac {
  &:before {
    content: $ut-ico-ac; 
  }
}
.ut-ico-admittance_free {
  &:before {
    content: $ut-ico-admittance_free; 
  }
}
.ut-ico-alcohol {
  &:before {
    content: $ut-ico-alcohol; 
  }
}
.ut-ico-all_year {
  &:before {
    content: $ut-ico-all_year; 
  }
}
.ut-ico-banquets {
  &:before {
    content: $ut-ico-banquets; 
  }
}
.ut-ico-beauty {
  &:before {
    content: $ut-ico-beauty; 
  }
}
.ut-ico-card_payment {
  &:before {
    content: $ut-ico-card_payment; 
  }
}
.ut-ico-childrens_corner {
  &:before {
    content: $ut-ico-childrens_corner; 
  }
}
.ut-ico-cognitive {
  &:before {
    content: $ut-ico-cognitive; 
  }
}
.ut-ico-conference_hall {
  &:before {
    content: $ut-ico-conference_hall; 
  }
}
.ut-ico-dance_floor {
  &:before {
    content: $ut-ico-dance_floor; 
  }
}
.ut-ico-family {
  &:before {
    content: $ut-ico-family; 
  }
}
.ut-ico-game_console {
  &:before {
    content: $ut-ico-game_console; 
  }
}
.ut-ico-gym {
  &:before {
    content: $ut-ico-gym; 
  }
}
.ut-ico-hookah {
  &:before {
    content: $ut-ico-hookah; 
  }
}
.ut-ico-karaoke {
  &:before {
    content: $ut-ico-karaoke; 
  }
}
.ut-ico-kids {
  &:before {
    content: $ut-ico-kids; 
  }
}
.ut-ico-kids_club {
  &:before {
    content: $ut-ico-kids_club; 
  }
}
.ut-ico-leisure_1 {
  &:before {
    content: $ut-ico-leisure_1; 
  }
}
.ut-ico-leisure_2 {
  &:before {
    content: $ut-ico-leisure_2; 
  }
}
.ut-ico-music {
  &:before {
    content: $ut-ico-music; 
  }
}
.ut-ico-parking {
  &:before {
    content: $ut-ico-parking; 
  }
}
.ut-ico-pets {
  &:before {
    content: $ut-ico-pets; 
  }
}
.ut-ico-relax {
  &:before {
    content: $ut-ico-relax; 
  }
}
.ut-ico-room-service1 {
  &:before {
    content: $ut-ico-room-service1; 
  }
}
.ut-ico-smoke {
  &:before {
    content: $ut-ico-smoke; 
  }
}
.ut-ico-spa {
  &:before {
    content: $ut-ico-spa; 
  }
}
.ut-ico-sports_broadcasts {
  &:before {
    content: $ut-ico-sports_broadcasts; 
  }
}
.ut-ico-summer_terrace {
  &:before {
    content: $ut-ico-summer_terrace; 
  }
}
.ut-ico-table_reservation {
  &:before {
    content: $ut-ico-table_reservation; 
  }
}
.ut-ico-takeaway {
  &:before {
    content: $ut-ico-takeaway; 
  }
}
.ut-ico-view1 {
  &:before {
    content: $ut-ico-view1; 
  }
}
.ut-ico-vine_map {
  &:before {
    content: $ut-ico-vine_map; 
  }
}
.ut-ico-vip {
  &:before {
    content: $ut-ico-vip; 
  }
}
.ut-ico-wifi1 {
  &:before {
    content: $ut-ico-wifi1; 
  }
}

