@import "../../../assets/scss/init";

.panel {
    align-items: flex-start;
    //justify-content: center;
    display: flex;
    flex-wrap: wrap;
}

.column {
    width: 50%;
    max-width: 320px;
    box-sizing: border-box;
    padding: 20px;
}

.column-full {
    width: 100%;
}

.input-group__title {
    color: #717171;
    font-weight: $normal;
    border-bottom: 1px solid currentColor;
    margin-top: 24px;
    margin-bottom: 12px;
    font-size: 16px;
    display: block;
    box-sizing: border-box;
    padding-bottom: 12px;
}

@media (max-width: #{$mobile_max}px) {
    .column {
        width: 100%;
        max-width: #{$mobile_max}px;
        box-sizing: border-box;
        padding: 20px;
    }
}
