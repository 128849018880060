@import "../../../assets/scss/_vars";
@import "../../../assets/scss/init";
.searchInput {
  font-size: 14px !important;
  border-bottom: none !important;
  outline: none;
  width: 80px;
  border: none;
  font-weight: 400;
  color: #000;
  background: none;
  cursor: pointer;
}
label {
  cursor: pointer;
}
i {
  cursor: pointer;
}

.picker-input {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 80px;
  min-width: 100px;
  margin-top: 3px;
  // padding-bottom: 3px;
  width: calc(100% - 30px);
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-weight: 400;
  color: #000;
  // line-height: 1;
  background: none;
  &:hover {
    cursor: pointer;
  }
}
.input-subfilter {
  height: 21px;

  font-size: 14px;
  font-weight: $normal;
  color: #000;
  background: none;

  &:hover {
    cursor: pointer;
  }
}
.input-category {
  height: 21px;
  font-size: 14px;
  font-weight: $normal;
  color: #000;
  background: none;
  &:hover {
    cursor: pointer;
  }
}
.moreWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.productAdults {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  p {
    font-size: 14px;
  }
}
.search {
  border-bottom: none;
  font-size: 14px;
  line-height: 1.5;
  width: 50px;
  margin-top: unset;
}
.clickOpen {
  position: absolute;
  top: 0;
  left: -42px;
  width: 165%;
  height: 100%;
  // background: red;
}
@media (max-width:#{$mobile_small_max}px) {
  .productAdults {
    justify-content: space-around;
    // margin-top: 8px;
  }
}
