@import "../../assets/scss/vars";
@import "../../assets/scss/theme";
.closedDetails--absolute-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 6;
}
.item--open {
	z-index: 7;
}

@media (min-width:#{$mobile_max+1}px) {
	.list--height {
		height: 100% !important;
	}
	.itemHoverBaloon {
		background-color: $ut-rojo;
		padding: 6px;
		display: inline-flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		border-radius: 6px;
		position: relative;
		z-index: 5;
		&:before {
			display: block;
			position: absolute;
			content: "";
			bottom: -10px;
			right: -10px;
			width: 20px;
			height: 10px;
			border-left: 10px solid $ut-rojo;

			border-bottom: 10px solid rgba(0, 0, 0, 0);
		}
		i,
		svg {
			color: white;
			font-size: 16px;
		}
		span {
			color: white;
			font-size: 12px;
		}
	}
	.mapNameProduct {
		padding: 8px;
	}
	.hideForClick {
		z-index: -1;
	}
	.info-addPadding {
		padding-bottom: 15px;
	}
	.serviceAcomodation {
		display: flex;
		flex-wrap: wrap;
		padding-top: 12px;
	}
	.more {
		margin-top: 10px;
	}
	.wrapperPartner {
		display: flex;
		justify-content: space-between;
		margin-top: 5px;
	}
	.serviceParnter {
		margin-bottom: 8px;
		i {
			color: #c0c0c0;
			margin-right: 10px;
			top: 3px;
		}
	}

	.subHeader {
		margin-top: 5px;
	}
	.labels {
		margin-top: 8px;
	}
	.buttonUnderline {
		text-decoration: none;
	}
	.spaceCorrect {
		justify-content: inherit !important;
	}
	.info-button {
		border-radius: 6px;
		border: solid 1px #eaecf0;
		background-color: #ffffff;
		font-family: SegoeUI;
		font-size: 20px;
		font-weight: 300;
		font-stretch: normal;
		font-style: 400;
		line-height: 1;
		letter-spacing: normal;
		text-align: center;
		color: #c8cdd5;
		display: block;
		outline: none;
		padding-top: 8px;
		padding-bottom: 12px;
		width: 100%;
		transition: 0.3s;
		cursor: pointer;
		margin-top: 13px;
		box-sizing: border-box;
	}
	.info-text {
		width: 60%;
		max-height: 68px;
		overflow-y: hidden;
		font-family: SegoeUI;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: normal;
		text-align: left;
		color: #202124;
		list-style-type: disc;
	}
	.wrapper-info {
		display: flex;
		justify-content: space-between;
		margin-top: 5px;
	}
	.wrapper-price {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.shareBtn {
		text-align: center;
		display: block;
		padding: 3px 8px;
		color: #c8cdd5;
		text-decoration: none;
		font-size: 16px;
	}
	.btn-more {
		display: block;
	}
	.filter {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		align-items: center;
		&-group {
			margin: 5px 5px;
			border-radius: 6px;
			border: solid 1px #d4d8e1;
			box-sizing: border-box;
			padding: 2px 10px 6px 10px;
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			flex-grow: 1;

			// justify-content: space-between;
			align-items: center;
			cursor: pointer;
			& > i,
			div {
				margin: 0 5px;
			}
			&:hover {
				// background-color: #ffffff;
				border: 1px solid currentColor;
				// opacity: 0.8;
			}
		}

		&-icon {
			font-size: 22px;
			color: #aaa;
		}

		&-label {
			display: block;
			font-size: 12px;
			font-weight: $light;
			color: #9d9d9d;
		}

		&-input {
			width: 70px;
			outline: none;
			border: none;
			background-color: rgba(255, 255, 255, 0);
			font-family: SegoeUI;
			font-size: 14px;
			font-weight: $normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.07;
			letter-spacing: normal;
			text-align: left;
			color: #000000;
		}
		&-select {
			width: 90px;
			outline: none;
			border: none;
			background-color: rgba(255, 255, 255, 0);
			font-family: SegoeUI;
			font-size: 14px;
			font-weight: $normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.07;
			letter-spacing: normal;
			text-align: left;
			color: #000000;
		}
		&-btn {
			height: 46px;
			background-color: inherit;
			display: flex;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			color: currentColor;
			outline: none;
			border: 1px solid currentColor;
			padding: 2px 10px 2px 10px;
			border-radius: 6px;
			text-transform: uppercase;
			font-family: SegoeUI;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			text-align: center;
			cursor: pointer;
			transition: 0.3s;
			opacity: 0.8;
			text-decoration: none;
			margin-left: 5px;
			flex-grow: 1;
			svg {
				font-size: 150%;
				margin-right: 6px;
				display: inline-block;
			}

			&:hover {
				transition: 0.3s;
				background-color: currentColor;
				color: #fff;
				span,
				svg {
					transition: 0.3s;
					color: #fff;
				}
				.item-btn-icon {
					color: #ffffff;
				}
			}
			span {
				color: currentColor;
				text-transform: uppercase;
			}
		}
		&-text {
			margin: 0;
			height: 21px;
			background: none;
			font-family: SegoeUI;
			font-size: 14px;
			font-weight: $normal;
			cursor: pointer;
			display: block;
			color: #000000;
		}
	}

	.main {
		width: 100%;
		display: flex;
		height: 624px;
		margin-top: 34px;
	}

	.data {
		margin-right: 7.5px;
		width: 65%;
		height: 100%;
	}

	.map {
		width: 35%;
		height: 100%;
		margin-left: 7.5px;
		border-radius: 6px;
		overflow: hidden;
		&-container {
			width: 100%;
			height: 100%;
		}

		&-filter {
			position: absolute;
			z-index: 1;
			transition: 0.5s;
			right: -100px;
			left: auto;
			top: 10px;
			background-color: #f6f8f9;
			box-sizing: border-box;
			padding-top: 10px;
			padding-bottom: 10px;
			border-radius: 20px;

			li {
				box-sizing: border-box;
				padding: 3px 5px;
				font-size: 36px;
				color: inherit;
				transition: 0.3s;
				&:hover {
					transition: 0.3s;
					color: darken(#d6d4d6, 20%);
				}
				label {
					color: inherit;
					i {
						color: inherit;
						cursor: pointer;
					}
					input:not(:checked) + i {
						color: #d6d4d6;
					}
					input:checked + i {
						color: inherit;
					}
					input {
						display: none;
					}
				}
			}
		}

		&:hover &-filter {
			transition: 0.5s;
			right: 10px;
		}

		&-item {
			background-color: $ut-azul;
			padding: 6px;
			display: inline-flex;
			text-align: center;
			align-items: center;
			justify-content: center;
			border-radius: 6px;
			position: relative;
			&:before {
				display: block;

				position: absolute;
				content: "";
				bottom: -10px;
				right: -10px;
				width: 20px;
				height: 10px;
				border-left: 10px solid $ut-azul;

				border-bottom: 10px solid rgba(0, 0, 0, 0);
			}
			i,
			svg {
				color: white;
				font-size: 16px;
			}
			span {
				color: white;
				font-size: 12px;
			}
		}

		&-balloon {
			position: relative;
			top: -216px;
			left: -90px;
			width: 220px;
			min-height: 180px;
			border-radius: 6px;
			background-color: #fff;
			z-index: 99;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
			ul {
				position: absolute;
				top: 12px;
				left: 12px;
				width: 100%;
				list-style: none;

				li {
					z-index: 100;
					display: inline-block;
					margin-right: 6px;
				}
			}
			img {
				position: relative;
				cursor: pointer;
				border-radius: 6px 6px 0 0;
				width: 100%;
				height: 125px;
				object-fit: cover;
			}
			a {
				margin-top: 10px;
				font-family: SegoeUI;
				font-size: 14px;
				font-weight: $normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.07;
				letter-spacing: normal;
				text-align: left;
				color: #000000;
				text-align: center;
				display: block;
				text-decoration: none;
				cursor: pointer;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.list {
		overflow-y: auto;
		height: calc(100% - 50px);
		scroll-behavior: smooth;
	}

	.btn {
		border-radius: 6px;
		border: solid 1px #eaecf0;
		background-color: #ffffff;
		font-family: SegoeUI;
		font-size: 20px;
		font-weight: 300;
		font-stretch: normal;
		font-style: $normal;
		line-height: 1;
		letter-spacing: normal;
		text-align: center;
		display: block;
		outline: none;
		padding-top: 8px;
		padding-bottom: 12px;
		width: 100%;
		transition: 0.3s;
		cursor: pointer;
		margin-top: 13px;
		box-sizing: border-box;
		&:hover {
			transition: 0.3s;
			background-color: currentColor;
			border-color: currentColor;
			span {
				color: #ffffff;
			}
		}
		span {
			color: #c8cdd5;
		}
	}
	//hack for hover
	.itemHover {
		width: 100%;
		height: 180px;
		display: flex;
		border-radius: 6px;
		overflow: hidden;
		transition: 0.5s;
		margin-top: 13px;
		&:first-of-type {
			margin-top: 0px;
		}

		transition: 0.5s;
		cursor: pointer;
		box-shadow: 0 0 10px lighten(#000, 80%);

		.item-socials {
			transition: 0.5s;
			transform: translateX(0px);
			position: absolute;
			top: 36px;
			left: 6px;
			z-index: 1;
			background: #f5f5f5;
			border-radius: 6px;
			overflow: hidden;

			padding: 3px 0px;

			li {
				// color: currentColor;
				&:first-child {
					display: none;
				}
				button {
					text-align: center;
					display: block;
					padding: 3px 8px !important;
					color: #c8cdd5 !important;
					text-decoration: none;
					font-size: 16px;
					outline: none;
					svg {
						color: #c8cdd5;
					}
				}
				a {
					text-align: center;
					display: block;
					padding: 3px 8px;
					color: #c8cdd5 !important;
					text-decoration: none;
					font-size: 16px;
					svg {
						color: #c8cdd5;
					}
				}
				// &:last-child {
				//   display: none;
				// }
			}

			button {
				&:hover {
					color: currentColor !important;
				}
			}
		}

		&-img {
			width: 37%;
			height: 100%;
			background-size: cover;
			background-position: 50% 50%;
			background-repeat: no-repeat;
		}

		&-like {
			position: absolute;
			top: 6px;

			left: 8px;
			z-index: 1;
			font-size: 24px;
			text-decoration: none;
			color: #fff;

			transition: 0.3s;
			input {
				display: none;
			}
			i {
				font-family: "icons";
				cursor: pointer;
			}
			i:before {
				text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
				content: "\f10d";
			}

			input:checked + i {
				transition: 0.3s;
				color: red;
				&:before {
					content: "\f10e";
				}
			}

			&:hover {
				transform: scale(1.2);
			}
		}
		&-link {
			position: absolute;
			left: 0%;
			width: 60%;
			height: 100%;
			top: 10%;
			z-index: 2;
		}
		&-data {
			padding: 10px 15px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			background-color: #fff;
			width: 63%;
		}
		&-title {
			font-family: SegoeUI;
			font-size: 20px;
			font-weight: $semi-bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
			text-align: left;
			color: #4e5255;
			text-decoration: none;
		}

		&-label {
			display: inline-block;
			box-sizing: border-box;
			margin: 0 1px;
			font-family: SegoeUI;
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
			text-align: left;
			color: #ffffff;
			border-radius: 3px;
			background-color: #66a3e9;
			padding: 3px 5px 5px;
			text-transform: lowercase;

			&--green {
				background-color: #39b7aa;
			}
			&--red {
				background-color: #fa6980;
			}
		}

		&-price {
			align-self: flex-end;
			font-family: SegoeUI;
			font-size: 26px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 0.77;
			letter-spacing: normal;
			text-align: left;
			color: #666666;
			margin-bottom: 5px;
			white-space: nowrap;
			span {
				font-size: 16px;
				font-weight: $normal;
				//`color: #9a9a9a;
				//text-decoration: line-through;
			}
		}

		&-hint {
			align-self: flex-end;
			font-family: SegoeUI;
			font-size: 12px;
			font-weight: $normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.67;
			letter-spacing: normal;
			text-align: left;
			color: #c0c0c0;
			margin-bottom: 5px;
		}
		&-text {
			align-self: flex-end;
			font-family: SegoeUI;
			font-size: 13px;
			font-weight: $normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.67;
			letter-spacing: normal;
			text-align: left;
			color: #333;
			max-height: 64px;
			overflow-y: hidden;
		}

		&-buttons {
			align-self: flex-end;
			z-index: 15;
		}

		&-btn {
			background-color: #fff;
			display: inline-block;
			box-sizing: border-box;
			color: currentColor;
			outline: none;
			border: 1px solid currentColor;
			padding: 11px 16px;
			border-radius: 3px;
			text-transform: uppercase;
			font-family: SegoeUI;
			font-size: 11px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			text-align: center;
			cursor: pointer;
			transition: 0.3s;
			opacity: 0.8;
			text-decoration: none;
			&:hover {
				transition: 0.3s;
				background-color: currentColor;
				color: #fff;
				span,
				i {
					transition: 0.3s;
					color: #fff;
				}
			}

			&-icon {
				background-color: currentColor;
				padding: 3px 7px;
				top: 9px;
				position: relative;
				font-size: 30px;
				line-height: 1;
				outline: none;
				border: none;
				transition: 0.3s;
				i,
				span {
					transition: 0.3s;
					color: #fff;
				}
			}
		}
	}
	//hack for hover end
	.item {
		width: 100%;
		height: 180px;
		display: flex;
		border-radius: 6px;
		overflow: hidden;
		transition: 0.5s;
		margin-top: 13px;
		&:first-of-type {
			margin-top: 0px;
		}
		&:hover {
			transition: 0.5s;
			cursor: pointer;
			box-shadow: 0 0 10px lighten(#000, 80%);
		}

		&-socials {
			transition: 0.5s;
			transform: translateX(-50px);
			position: absolute;
			top: 36px;
			left: 6px;
			z-index: 1;
			background: #f5f5f5;
			border-radius: 6px;
			overflow: hidden;

			padding: 3px 0px;

			li {
				// color: currentColor;
				&:first-child {
					display: none;
				}
				button {
					text-align: center;
					display: block;
					padding: 3px 8px !important;
					color: #c8cdd5 !important;
					text-decoration: none;
					font-size: 16px;
					outline: none;
					svg {
						color: #c8cdd5;
					}
				}
				a {
					text-align: center;
					display: block;
					padding: 3px 8px;
					color: #c8cdd5 !important;
					text-decoration: none;
					font-size: 16px;
					svg {
						color: #c8cdd5;
					}
				}
			}

			button {
				&:hover {
					color: currentColor !important;
				}
			}
		}

		&:hover &-socials {
			transition: 0.5s;
			transform: translateX(0px);
		}

		&-img {
			width: 37%;
			height: 100%;
			background-size: cover;
			background-position: 50% 50%;
			background-repeat: no-repeat;
		}

		&-like {
			position: absolute;
			top: 6px;

			left: 8px;
			z-index: 1;
			font-size: 24px;
			text-decoration: none;
			color: #fff;

			transition: 0.3s;
			input {
				display: none;
			}
			i {
				font-family: "icons";
				cursor: pointer;
			}
			i:before {
				text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
				content: "\f10d";
			}

			input:checked + i {
				transition: 0.3s;
				color: red;
				&:before {
					content: "\f10e";
				}
			}

			&:hover {
				transform: scale(1.2);
			}
		}
		&-link {
			position: absolute;
			left: 0%;
			width: 100%;
			height: 100%;
			top: 0;
			z-index: 2;
		}
		&-data {
			padding: 10px 15px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			background-color: #fff;
			width: 63%;
			&:hover {
				.item-title {
					color: currentColor;
				}
			}
		}
		&-title {
			font-family: SegoeUI;
			font-size: 20px;
			font-weight: $semi-bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
			text-align: left;
			color: #4e5255;
			text-decoration: none;
		}

		&-label {
			display: inline-block;
			box-sizing: border-box;
			margin: 0 1px;
			font-family: SegoeUI;
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
			text-align: left;
			color: #ffffff;
			border-radius: 3px;
			background-color: #66a3e9;
			padding: 3px 5px 5px;
			text-transform: lowercase;

			&--green {
				background-color: #39b7aa;
			}
			&--red {
				background-color: #fa6980;
			}
		}

		&-price {
			align-self: flex-end;
			font-family: SegoeUI;
			font-size: 26px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 0.77;
			letter-spacing: normal;
			text-align: left;
			color: #666666;
			margin-bottom: 5px;
			white-space: nowrap;
			span {
				font-size: 16px;
				font-weight: $normal;
				//color: #9a9a9a;
				//text-decoration: line-through;
			}
		}

		&-hint {
			align-self: flex-end;
			font-family: SegoeUI;
			font-size: 12px;
			font-weight: $normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.67;
			letter-spacing: normal;
			text-align: left;
			color: #c0c0c0;
			margin-bottom: 5px;
		}
		&-text {
			align-self: flex-end;
			font-family: SegoeUI;
			font-size: 13px;
			font-weight: $normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.67;
			letter-spacing: normal;
			text-align: left;
			color: #333;
			max-height: 64px;
			overflow-y: hidden;
		}

		&-buttons {
			align-self: flex-end;
			z-index: 15;
		}

		&-btn {
			background-color: #fff;
			display: inline-block;
			box-sizing: border-box;
			color: currentColor;
			outline: none;
			border: 1px solid currentColor;
			padding: 11px 16px;
			border-radius: 3px;
			text-transform: uppercase;
			font-family: SegoeUI;
			font-size: 11px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			text-align: center;
			cursor: pointer;
			transition: 0.3s;
			opacity: 0.8;
			text-decoration: none;
			&:hover {
				transition: 0.3s;
				background-color: currentColor;
				color: #fff;
				span,
				i {
					transition: 0.3s;
					color: #fff;
				}
			}

			&-icon {
				// background-color: currentColor;
				// padding: 3px 7px;
				top: 2px;
				left: -3px;
				position: relative;
				font-size: 19px;
				line-height: 1;
				outline: none;
				border: none;
				transition: 0.3s;
				i,
				span {
					transition: 0.3s;
					color: #fff;
				}

				// &:hover {
				//   transition: 0.3s;
				//   background-color: #fff;
				//   i,
				//   span {
				//     transition: 0.3s;
				//     color: currentColor;
				//   }
				// }
			}
		}
	}
	.wrapper-btn {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
	}
	.wrapper-btn-mobile {
		display: none;
		justify-content: space-around;
		flex-wrap: wrap;
	}
}

@media (max-width: 1027px) {
	.filter-title {
		width: 100%;
		margin-top: 8px;
		margin-bottom: 16px;
	}
}
@media (max-width: 1000px) {
	.item {
		height: auto;
	}

	.itemHover {
		height: auto;
	}

	.labels {
		margin-top: 8px;
	}
	.item-hint {
		align-self: auto;
	}
	.item-price {
		align-self: center;
		margin-top: 8px;
	}
	.item-buttons {
		align-self: center;
		z-index: 15;
	}
	.wrapper-info {
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 8px;
	}
	.item-img {
		height: auto;
	}
	.info-text {
		width: auto;
	}
	.wrapper-price {
		align-items: center;
	}
}
@media (max-width: 440px) {
	.filter-btn {
		width: 100%;
	}
}

@media (max-width:#{$mobile_max}px) {
	.itemHoverBaloon {
		background-color: $ut-rojo;
		padding: 6px;
		display: inline-flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		border-radius: 6px;
		position: relative;
		z-index: 5;
		&:before {
			display: block;
			position: absolute;
			content: "";
			bottom: -10px;
			right: -10px;
			width: 20px;
			height: 10px;
			border-left: 10px solid $ut-rojo;

			border-bottom: 10px solid rgba(0, 0, 0, 0);
		}
		i {
			color: white;
			font-size: 16px;
		}
		span {
			color: white;
			font-size: 12px;
		}
	}
	.mapNameProduct {
		padding: 8px;
	}
	.hideForClick {
		z-index: -1;
	}
	.serviceAcomodation {
		margin-top: 12px;
		display: flex;
		flex-wrap: wrap;
	}
	.serviceParnter {
		margin-bottom: 10px;
		i {
			color: #c0c0c0;
			margin-right: 10px;
			top: 3px;
		}
	}

	.more {
		margin-top: 15px;
	}
	.wrapper-price {
		align-items: center;
		justify-content: center;
		display: flex;
		flex-direction: column;
	}
	.wrapper-info {
		justify-content: center;
	}
	.info-text {
		color: #000000;
		margin-top: 10px;
	}
	.buttonUnderline {
		text-decoration: none;
	}

	.filter {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		align-items: center;
		&-group {
			margin: 5px 5px;
			border-radius: 6px;
			border: solid 1px #d4d8e1;
			box-sizing: border-box;
			padding: 2px 10px 6px 10px;
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			flex-grow: 1;
			// justify-content: space-between;
			align-items: center;
			& > i,
			div {
				margin: 0 5px;
			}
			&:hover {
				// background-color: #ffffff;
				border: 1px solid currentColor;
			}
		}

		&-icon {
			font-size: 22px;
			color: #d4d8e1;
		}

		&-label {
			display: block;
			font-size: 12px;
			font-weight: $light;
			color: #9d9d9d;
		}

		&-input {
			width: 70px;
			outline: none;
			border: none;
			background-color: rgba(255, 255, 255, 0);
			font-family: SegoeUI;
			font-size: 14px;
			font-weight: $normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.07;
			letter-spacing: normal;
			text-align: left;
			color: #000000;
		}
		&-select {
			width: 90px;
			outline: none;
			border: none;
			background-color: rgba(255, 255, 255, 0);
			font-family: SegoeUI;
			font-size: 14px;
			font-weight: $normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.07;
			letter-spacing: normal;
			text-align: left;
			color: #000000;
		}
		&-btn {
			height: 46px;
			background-color: inherit;
			display: flex;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			color: currentColor;
			outline: none;
			border: 1px solid currentColor;
			padding: 2px 10px 2px 10px;
			border-radius: 6px;
			text-transform: uppercase;
			font-family: SegoeUI;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			text-align: center;
			cursor: pointer;
			transition: 0.3s;
			opacity: 0.8;
			text-decoration: none;
			margin-left: 5px;
			flex-grow: 1;

			&:hover {
				transition: 0.3s;
				background-color: currentColor;
				color: #fff;
				span {
					transition: 0.3s;
					color: #fff;
				}
				.item-btn-icon {
					color: #ffffff;
				}
			}
			span {
				color: currentColor;
				text-transform: uppercase;
			}
		}
		&-text {
			margin: 0;
			height: 21px;
			background: none;
			font-family: SegoeUI;
			font-size: 14px;
			font-weight: $normal;
			cursor: pointer;
			display: block;
			color: #000000;
		}
	}

	.main {
		width: 100%;
		display: flex;

		margin-top: 34px;
		flex-direction: column;
	}

	.data {
		// margin-right: 7.5px;
		width: 100%;
		// height: 634px;
	}
	// .data {
	//   margin-right: 7.5px;
	//   width: 65%;
	//   height: 100%;
	// }

	.map {
		width: 0px;
		height: 100%;
		margin-left: 7.5px;
		border-radius: 6px;
		overflow: hidden;
		&-container {
			width: 100%;
			height: 100%;
		}

		&-filter {
			position: absolute;
			z-index: 1;
			transition: 0.5s;
			right: -100px;
			left: auto;
			top: 10px;
			background-color: #f6f8f9;
			box-sizing: border-box;
			padding-top: 10px;
			padding-bottom: 10px;
			border-radius: 20px;

			li {
				box-sizing: border-box;
				padding: 3px 5px;
				font-size: 36px;
				color: inherit;
				transition: 0.3s;
				&:hover {
					transition: 0.3s;
					color: darken(#d6d4d6, 20%);
				}
				label {
					color: inherit;
					i {
						color: inherit;
						cursor: pointer;
					}
					input:not(:checked) + i {
						color: #d6d4d6;
					}
					input:checked + i {
						color: inherit;
					}
					input {
						display: none;
					}
				}
			}
		}

		&:hover &-filter {
			transition: 0.5s;
			right: 10px;
		}

		&-item {
			color: white;
			background-color: $ut-azul;
			padding: 6px 6px;
			display: inline-flex;
			text-align: center;
			align-items: center;
			justify-content: center;
			border-radius: 6px;
			transform: translate(-50%, -50%);

			i,
			span {
				color: white;
			}
			span {
				font-size: 12px;
			}
			i {
				font-size: 20px;
			}
		}
	}

	.list {
		overflow-y: auto;
		height: auto;
		scroll-behavior: smooth;
	}

	.btn {
		border-radius: 6px;
		border: solid 1px #eaecf0;
		background-color: #ffffff;
		font-family: SegoeUI;
		font-size: 20px;
		font-weight: 300;
		font-stretch: normal;
		font-style: $normal;
		line-height: 1;
		letter-spacing: normal;
		text-align: center;
		color: #c8cdd5;
		display: block;
		outline: none;
		padding-top: 8px;
		padding-bottom: 12px;
		width: 100%;
		transition: 0.3s;
		cursor: pointer;
		margin-top: 13px;
		box-sizing: border-box;
		&:hover {
			transition: 0.3s;
			background-color: currentColor;
			span {
				color: #ffffff;
			}
		}
		span {
			color: #c8cdd5;
		}
	}

	.item {
		width: 100%;
		height: auto;
		display: flex;
		border-radius: 6px;
		overflow: hidden;
		transition: 0.5s;
		flex-flow: column;
		margin-top: 13px;
		&:first-of-type {
			margin-top: 0px;
		}
		&:hover {
			transition: 0.5s;
			cursor: pointer;
			box-shadow: 0 0 10px lighten(#000, 80%);
		}
		&-link {
			position: absolute;
			left: 0%;
			width: 100%;
			height: 100%;
			top: 0;
			z-index: 2;
		}

		&-socials {
			display: block;
			position: absolute;
			top: 35px;
			left: 12px;
			z-index: 1;
			overflow: hidden;
			// padding: 8px;
			border-radius: 4px;
			li {
				display: none;
				&:first-child {
					display: block;

					button {
						padding: 8px !important;
						background: #f5f5f5 !important;
						margin-bottom: 5px;
						border-radius: 6px;
						outline: none;
						border: none;
						color: currentColor;
						svg {
							font-size: 20px;
							display: flex;
							color: currentColor;
						}
					}
				}
			}
			// li:hover {
			//   a {
			//     color: currentColor;
			//   }
			// }
		}

		&:hover &-socials {
			transition: 0.5s;
			transform: translateX(0px);
		}

		&-img {
			display: block;
			width: 100%;
			height: 180px;
			background-size: cover;
			background-position: 50% 50%;
			background-repeat: no-repeat;
		}

		&-like {
			position: absolute;
			top: 6px;

			left: 8px;
			z-index: 1;
			font-size: 24px;
			text-decoration: none;
			color: #fff;

			transition: 0.3s;
			input {
				display: none;
			}
			i {
				font-family: "icons";
				cursor: pointer;
			}
			i:before {
				text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
				content: "\f10d";
			}

			input:checked + i {
				transition: 0.3s;
				color: red;
				&:before {
					content: "\f10e";
				}
			}

			&:hover {
				transform: scale(1.2);
			}
		}
		&-data {
			padding: 10px 15px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			background-color: #fff;
			width: 100%;
			justify-content: space-between;
			padding-bottom: 29px;
		}
		&-title {
			margin-bottom: 10px;
			font-family: SegoeUI;
			font-size: 20px;
			font-weight: $semi-bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
			text-align: left;
			color: #4e5255;
			text-decoration: none;
		}

		&-label {
			display: inline-block;
			box-sizing: border-box;
			margin: 0 1px;
			font-family: SegoeUI;
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
			text-align: left;
			color: #ffffff;
			border-radius: 3px;
			background-color: #66a3e9;
			padding: 3px 5px 5px;
			text-transform: lowercase;

			&--green {
				background-color: #39b7aa;
			}
			&--red {
				background-color: #fa6980;
			}
		}

		&-price {
			align-self: center;
			font-family: SegoeUI;
			font-size: 26px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 0.77;
			letter-spacing: normal;
			text-align: left;
			color: #666666;
			margin-bottom: 5px;
			white-space: nowrap;
			span {
				font-size: 16px;
				font-weight: $normal;
				//color: #9a9a9a;
				// text-decoration: line-through;
			}
		}

		&-hint {
			align-self: center;
			font-family: SegoeUI;
			font-size: 12px;
			font-weight: $normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.67;
			letter-spacing: normal;
			text-align: left;
			color: #c0c0c0;
			margin-bottom: 5px;
		}
		&-text {
			margin-top: 12px;
			margin-bottom: 12px;
			align-self: flex-start;
			font-family: SegoeUI;
			font-size: 13px;
			font-weight: $normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.67;
			letter-spacing: normal;
			text-align: left;
			color: #333;
			max-height: auto;
			overflow-y: auto;
		}

		&-buttons {
			align-self: center;
			z-index: 15;
		}

		&-btn {
			background-color: #fff;
			display: inline-block;
			box-sizing: border-box;
			color: currentColor;
			outline: none;
			border: 1px solid currentColor;
			padding: 11px 48px;
			border-radius: 3px;
			text-transform: uppercase;
			font-family: SegoeUI;
			font-size: 11px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			text-align: center;
			cursor: pointer;
			transition: 0.3s;
			opacity: 0.8;
			&:hover {
				transition: 0.3s;
				background-color: currentColor;
				color: #fff;
				span,
				i {
					transition: 0.3s;
					color: #fff;
				}
			}

			&-icon {
				top: 2px;
				left: -3px;
				position: relative;
				font-size: 19px;
				line-height: 1;
				outline: none;
				border: none;
				transition: 0.3s;
				i,
				span {
					transition: 0.3s;
					color: #fff;
				}

				// &:hover {
				//   transition: 0.3s;
				//   background-color: #fff;
				//   i,
				//   span {
				//     transition: 0.3s;
				//     color: currentColor;
				//   }
				// }
			}
		}
	}
	//hack for googlemap effect start
	.itemHack {
		width: 100%;
		height: auto;
		display: flex;
		border-radius: 6px;
		overflow: hidden;
		transition: 0.5s;
		flex-flow: column;
		margin-top: 13px;
		&:first-of-type {
			margin-top: 0px;
		}

		transition: 0.5s;
		cursor: pointer;
		box-shadow: 0 0 10px lighten(#000, 80%);

		&-link {
			position: absolute;
			left: 0%;
			width: 100%;
			height: 100%;
			top: 0;
			z-index: 2;
		}

		&-socials {
			display: block;
			position: absolute;
			top: 35px;
			left: 12px;
			z-index: 1;
			overflow: hidden;
			transition: 0.5s;
			transform: translateX(0px);
			// padding: 8px;
			border-radius: 4px;

			// li {
			//   button {
			//     padding: 8px !important;
			//     background: #f5f5f5 !important;
			//     margin-bottom: 5px;
			//     border-radius: 6px;
			//     i {
			//       font-size: 18px;
			//       display: flex;
			//     }
			//   }
			// }
			li {
				display: none;
				&:first-child {
					display: block;

					button {
						padding: 8px !important;
						background: #f5f5f5 !important;
						margin-bottom: 5px;
						border-radius: 6px;
						outline: none;
						border: none;
						color: currentColor;
						svg {
							font-size: 20px;
							display: flex;
							color: currentColor;
						}
					}
				}
			}
			// li:hover {
			//   a {
			//     color: currentColor;
			//   }
			// }
		}

		&-img {
			display: block;
			width: 100%;
			height: 180px;
			background-size: cover;
			background-position: 50% 50%;
			background-repeat: no-repeat;
		}

		&-like {
			position: absolute;
			top: 6px;

			left: 8px;
			z-index: 1;
			font-size: 24px;
			text-decoration: none;
			color: #fff;

			transition: 0.3s;
			input {
				display: none;
			}
			i {
				font-family: "icons";
				cursor: pointer;
			}
			i:before {
				text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
				content: "\f10d";
			}

			input:checked + i {
				transition: 0.3s;
				color: red;
				&:before {
					content: "\f10e";
				}
			}

			&:hover {
				transform: scale(1.2);
			}
		}
		&-data {
			padding: 10px 15px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			background-color: #fff;
			width: 100%;
			justify-content: space-between;
			padding-bottom: 29px;
		}
		&-title {
			margin-bottom: 10px;
			font-family: SegoeUI;
			font-size: 20px;
			font-weight: $semi-bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
			text-align: left;
			color: #4e5255;
			text-decoration: none;
		}

		&-label {
			display: inline-block;
			box-sizing: border-box;
			margin: 0 1px;
			font-family: SegoeUI;
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
			text-align: left;
			color: #ffffff;
			border-radius: 3px;
			background-color: #66a3e9;
			padding: 3px 5px 5px;
			text-transform: lowercase;

			&--green {
				background-color: #39b7aa;
			}
			&--red {
				background-color: #fa6980;
			}
		}

		&-price {
			align-self: center;
			font-family: SegoeUI;
			font-size: 26px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 0.77;
			letter-spacing: normal;
			text-align: left;
			color: #666666;
			margin-bottom: 5px;
			white-space: nowrap;
			span {
				font-size: 16px;
				font-weight: $normal;
				//color: #9a9a9a;
				// text-decoration: line-through;
			}
		}

		&-hint {
			align-self: center;
			font-family: SegoeUI;
			font-size: 12px;
			font-weight: $normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.67;
			letter-spacing: normal;
			text-align: left;
			color: #c0c0c0;
			margin-bottom: 5px;
		}
		&-text {
			margin-top: 12px;
			margin-bottom: 12px;
			align-self: flex-start;
			font-family: SegoeUI;
			font-size: 13px;
			font-weight: $normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.67;
			letter-spacing: normal;
			text-align: left;
			color: #333;
			max-height: auto;
			overflow-y: auto;
		}

		&-buttons {
			align-self: center;
			z-index: 15;
		}

		&-btn {
			background-color: #fff;
			display: inline-block;
			box-sizing: border-box;
			color: currentColor;
			outline: none;
			border: 1px solid currentColor;
			padding: 11px 48px;
			border-radius: 3px;
			text-transform: uppercase;
			font-family: SegoeUI;
			font-size: 11px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			text-align: center;
			cursor: pointer;
			transition: 0.3s;
			opacity: 0.8;
			&:hover {
				transition: 0.3s;
				background-color: currentColor;
				color: #fff;
				span,
				i {
					transition: 0.3s;
					color: #fff;
				}
			}

			&-icon {
				background-color: currentColor;
				padding: 3px 7px;
				top: 9px;
				position: relative;
				font-size: 30px;
				line-height: 1;
				outline: none;
				border: none;
				transition: 0.3s;
				i,
				span {
					transition: 0.3s;
					color: #fff;
				}
			}
		}
	}
	//hack for googlemap effect end
	.displayBlock {
		display: block;
		position: absolute;
		top: 35px;
		left: 12px;
		z-index: 1;
		overflow: hidden;
		background: none;
		border-radius: 4px;
		background: #f5f5f5 !important;

		li {
			display: block;
			&:first-child {
				button {
					padding: 8px !important;
					background: #f5f5f5 !important;
					margin-bottom: 5px;
					border-radius: 6px;
					outline: none;
					border: none;
					color: currentColor;
					svg {
						font-size: 20px;
						display: flex;
						color: currentColor;
					}
				}
			}
			button {
				padding: 8px !important;
				background: #f5f5f5 !important;
				margin-bottom: 5px;
				border-radius: 6px;
				i {
					font-size: 18px;
					display: flex;
				}
			}
		}
	}
	.wrapper-btn {
		display: none;
	}
	.wrapper-btn-mobile {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
	}
	.btn-more {
		display: none;
	}
}
@media (max-width: 460px) {
	.filter-btnMobile {
		margin-top: 8px;
	}
	.filter-btn {
		margin: 3px 0px 0px 0px;
	}
}
@media (max-width:#{$mobile_small_max}px) {
	.itemHoverBaloon {
		background-color: $ut-rojo;
		padding: 6px;
		display: inline-flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		border-radius: 6px;
		position: relative;
		z-index: 5;
		&:before {
			display: block;
			position: absolute;
			content: "";
			bottom: -10px;
			right: -10px;
			width: 20px;
			height: 10px;
			border-left: 10px solid $ut-rojo;

			border-bottom: 10px solid rgba(0, 0, 0, 0);
		}
		i {
			color: white;
			font-size: 16px;
		}
		span {
			color: white;
			font-size: 12px;
		}
	}
	.btn-more {
		display: block;
		margin-right: 5px;
		margin-bottom: 5px;
		text-transform: uppercase;
		border-radius: 4px;
		border: solid 1px #d4d8e1;
		background-color: #d4d8e1;
		font-family: SegoeUI;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		text-align: center;
		color: #798493;
		padding: 12px 24px;
		cursor: pointer;
		flex-grow: 1;
		&:focus {
			outline: none;
		}
		transition: box-shadow 0.3s;
		&:hover {
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
		}
		&--on {
			object-fit: contain;
			border-radius: 4px;
			background-color: #798493;
			font-family: SegoeUI;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.25;
			letter-spacing: normal;
			text-align: center;
			color: #dfdfdf;
		}
	}
}
@media (max-width:#{$tablet_max}px) {
	.item-link {
		position: absolute;
		left: 0%;
		width: 100%;
		height: 100%;
		top: 0;
		z-index: 2;
	}
}
