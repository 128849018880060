@import "../../assets/scss/vars";
@import "../../assets/scss/init";

.filter {
  margin-top: 16px;
  margin-bottom: 32px;
  display: flex;
}

.filter-group {
  border-radius: 6px;
  border: solid 1px #d4d8e1;
  background-color: #f6f8f9;
  padding: 14px 19px;
  display: inline-flex;
  flex-direction: row;
  margin-right: 10px;
  flex-basis: min-content;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: #ffffff;
    border: 1px solid currentColor;
    .filter-input {
      background: #ffffff;
    }
  }
}

.filter-icon {
  color: #9d9d9d;
  font-size: 26px;
  margin-right: 11px;
}

.filter-label {
  font-family: SegoeUI;
  font-size: 12px;
  font-weight: $bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  text-transform: uppercase;
  color: #9d9d9d;
  margin-right: 8px;
}
.label-more {
  margin-top: 4px;
}
.filter-input {
  font-family: SegoeUI;
  font-size: 12px;
  font-weight: $bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  text-transform: uppercase;
  color: #000;
  background: #f6f8f9;
  margin-right: 8px;
  border: none;
  outline: none;
  width: 70px;
  &:hover {
    background: #ffffff;
  }
}

.filter-select {
  font-family: SegoeUI;
  font-size: 12px;
  font-weight: $bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  text-transform: uppercase;
  color: #000;
  background: #fff;
  margin-right: 8px;
  border: none;
  outline: none;
}

.filter-btn {
  padding: 21px 36px;
  text-transform: uppercase;
  border-radius: 6px;
  border: solid 1px currentColor;
  background-color: #f6f8f9;
  font-family: SegoeUI;
  font-size: 12px;
  font-weight: $normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  flex-basis: 100%;
  color: currentColor;

  &:hover {
    transition: 0.3s;
    background-color: currentColor;
    span {
      transition: 0.3s;
      color: #fff;
    }
  }
  span {
    color: currentColor;
    text-transform: uppercase;
  }
}

.wrappMore {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (min-width:#{$mobile-max+1}px) and (max-width:#{$tablet-max}px) {
  .filter {
    margin-top: 16px 12px 32px;

    display: flex;
  }
  .filter-label {
    font-family: SegoeUI;
    font-size: 12px;
    font-weight: $bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    text-transform: uppercase;
    color: #9d9d9d;
    display: block;
    margin-right: 8px;
  }

  .filter-group {
    border-radius: 6px;
    border: solid 1px #d4d8e1;
    background-color: #f6f8f9;
    padding: 14px 19px;
    display: inline-flex;
    flex-direction: row;
    margin-right: 10px;
    flex-basis: min-content;
    align-items: center;
    &:hover {
      background: #ffffff;
      border: 1px solid currentColor;
      .filter-input {
        background: #ffffff;
      }
    }
  }
}

@media (max-width:#{$mobile-max}px) {
  .filter {
    margin-top: 16px;
    margin-bottom: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .filter-group {
    border-radius: 6px;
    border: solid 1px #d4d8e1;
    background-color: #f6f8f9;
    padding: 14px 6px;
    display: inline-flex;
    box-sizing: border-box;
    flex-direction: row;
    flex-basis: calc(50% - 5px);
    margin-right: 0px;
    margin-top: 8px;
    margin-bottom: 2px;
    &:nth-of-type(3) {
      width: 100%;
      flex-basis: 100%;
    }
    &:hover {
      background: #ffffff;
      border: 1px solid currentColor;
      .filter-input {
        background: #ffffff;
      }
    }
  }

  .filter-icon {
    color: #9d9d9d;
    font-size: 26px;
    margin-right: 11px;
  }

  .filter-label {
    font-family: SegoeUI;
    font-size: 12px;
    font-weight: $bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    text-transform: uppercase;
    color: #9d9d9d;
    margin-right: 8px;
  }
  .filter-input {
    font-family: SegoeUI;
    font-size: 12px;
    font-weight: $bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    text-transform: uppercase;
    color: #000;
    background: #f6f8f9;
    margin-right: 8px;
    border: none;
    outline: none;
  }

  .filter-select {
    font-family: SegoeUI;
    font-size: 12px;
    font-weight: $bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    text-transform: uppercase;
    color: #000;
    background: #fff;
    margin-right: 8px;
    border: none;
    outline: none;
  }

  .filter-btn {
    padding: 21px 36px;
    text-transform: uppercase;
    border-radius: 6px;
    border: solid 1px currentColor;
    background-color: #f6f8f9;
    font-family: SegoeUI;
    font-size: 12px;
    font-weight: $normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    flex-basis: 100%;
    color: currentColor;
    margin-top: 8px;

    &:hover {
      transition: 0.3s;
      background-color: currentColor;
      span {
        transition: 0.3s;
        color: #fff;
      }
    }
    span {
      color: currentColor;
      text-transform: uppercase;
    }
  }
}

// background-color: inherit;
// // display: inline-block;
// box-sizing: border-box;
// color: currentColor;
// outline: none;
// border: 1px solid currentColor;
// padding: 2px 10px 2px 10px;
// border-radius: 6px;
// text-transform: uppercase;
// font-family: SegoeUI;
// font-size: 12px;
// font-weight: normal;
// font-stretch: normal;
// font-style: normal;
// letter-spacing: normal;
// text-align: center;
// cursor: pointer;
// transition: 0.3s;
// opacity: 0.8;
// text-decoration: none;
// margin-left: 5px;
// flex-grow: 1;

//     transition: 0.3s;
// background-color: currentColor;
