@import "../../../assets/scss/init";

.button {
	display: inline-block;
	margin: 3px 10px 17px 0px;
	border-radius: 6px;
	cursor: pointer;
	outline: none;
	border-width: 1px;
	border-style: solid;
	padding: 16px 50px;
	font-family: inherit;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	text-transform: uppercase;
	text-align: center;
	&:disabled {
		cursor: default;
	}

	&-more {
		border-color: rgba(0, 0, 0, 0.4);
		color: rgba(0, 0, 0, 0.4);
		background-color: #fff;
		transition: 0.5s;
		&:hover {
			border-color: currentColor;
			color: currentColor;
		}
	}
	&-post {
		border-color: currentColor;
		color: currentColor;
		background-color: #fff;
		transition: 0.5s;
		&:hover {
			background-color: currentColor;
			span {
				color: #fff;
			}
		}
		&:disabled {
			border-color: rgba(0, 0, 0, 0.2);
			color: rgba(0, 0, 0, 0.2);
			&:hover {
				background-color: #fff;
				span {
					color: rgba(0, 0, 0, 0.2);
				}
			}
		}
	}
}
