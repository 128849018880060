@import "../../../assets/scss/init";

.group {
  position: relative;
  flex-grow: 2;
}
.list-overlay {
  z-index: #{$dropdown-level - 1};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.list {
  position: absolute;
  background-color: #fff;
  z-index: $dropdown-level;
  top: 30px;
  left: 0;
  width: 100%;
  height: auto;
  border: 1px solid #eee;
}
.item {
  font-family: "Segoe UI", sans-serif;
  font-size: 12px;
  font-weight: $normal;
  cursor: pointer;
  color: #000000;
  border-bottom: 1px solid #eee;
  padding: 6px 12px;
  box-sizing: border-box;
  span {
    display: block;
    font-size: 9px;
    color: #666;
  }
  span:nth-of-type(2) {
    position: absolute;
    display: block;
    padding: 5px;

    right: 5px;
    top: 12px;
    font-size: 12px;
    a {
      color: currentColor;
    }
  }
  &:hover {
    background: #eee;
  }
}
.activeItem {
  background: #eee;
}
.loading {
  position: absolute;
  top: -6px;
  right: 20px;
  width: 36px;
  height: 36px;
}
.clear {
  position: absolute;
  right: 0;
  top: 3px;
  box-sizing: border-box;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  background: #666;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  &:after {
    display: block;
    position: relative;
    text-align: center;
    top: 1px;
    content: "✖";
  }
  &:hover {
    background: desaturate(red, 30%);
  }
}
