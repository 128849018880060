@import "../../assets/scss/vars";

.container {
    margin-top: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.title {
    line-height: 2;
    font-size: 24px;
    font-weight: $bold;
    text-align: center;
}

.text {
    font-size: 16px;
    font-weight: $medium;
    text-align: center;
    color: #555;
}

@media (orientation: portrait) {
    .container {
        margin-top: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 320px) {
    .container {
        margin-top: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
