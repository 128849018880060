@import "../../../assets/scss/init";

.review__tooltip {
  background: currentColor !important;
  &::after {
    border-top-color: currentColor !important;
  }
  span {
    color: #ffffff;
  }
}
.N {
  background-color: #fa6980;
}
.P {
  background-color: #66a3e9;
}
.F {
  background-color: #39b7aa;
}
.data {
  text-align: left;
}
.header-field {
  text-align: center;
}
.wrapp-product {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.wrapp-detail {
  color: #707070;
  font-size: 14px;
  p {
    margin-left: 16px;
    margin-bottom: 8px;
    span {
      margin-left: 10px;
    }
  }
}
.childWrapper {
  flex-grow: 1;
  flex-basis: auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 600;
}
.childTitle {
  width: 50%;
  span {
    margin-left: 3px;
  }
}
.childTitle--light-weight {
  font-weight: normal;
}
.data--triangle {
  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #c8cdd5;
    position: absolute;
    top: 40%;
    margin-left: 8px;
  }
}
.data--triangle-reverse {
  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #c8cdd5;
    position: absolute;
    top: 40%;
    margin-left: 8px;
  }
}
.detailOrder {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 50%;
  font-family: SegoeUI;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: left;
  margin: 16px;
  color: #707070;
}

.purchases {
  background: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  margin: 10px;
  transition: box-shadow 0.3s;
  box-shadow: none;
  &-head {
    padding: 16px;
  }
  &-row {
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    font-family: SegoeUI;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left;
    margin: 8px 0;
    color: #707070;
  }
  &-header {
    background-color: #f6f8f9;
    text-transform: uppercase;
    color: #c8cdd5;
  }
  &-data {
    flex-grow: 1;
    text-align: center;
    flex-basis: 50%;
    &--grow {
      flex-grow: 2;
      text-align: center;
    }
  }
}
.purchases-item-status {
  border-radius: 4px;
  padding: 3px;
}
.item--basis {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
  display: block;
  border-radius: 6px;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.item-data {
  margin-left: 5px;
  display: flex;
  align-items: center;
  width: 80%;
  justify-content: space-between;
  margin-bottom: 16px;
  color: #707070;
}
.purchases-history {
  display: flex;

  flex-direction: column;
}
.purchases-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 16px;
}

.purchases-btn {
  margin: 0 auto;
  border-radius: 6px;
  border: solid 1px currentColor;
  background-color: transparent;
  font-family: SegoeUI;
  font-weight: 300;
  font-stretch: normal;
  font-style: $normal;
  font-size: 12px;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  display: block;
  outline: none;
  padding-top: 8px;
  padding-bottom: 12px;
  width: auto;
  transition: 0.3s;
  cursor: pointer;
  box-sizing: border-box;
  &:hover {
    transition: 0.3s;
    background-color: currentColor;
    border-color: currentColor;
    span {
      color: #ffffff;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
  span {
    color: currentColor;
    text-transform: uppercase;
  }
}
@media (max-width: 1000px) {
  .item--basis {
    flex-basis: 40%;
  }
  .item-data {
    width: 80%;
  }
}

@media (max-width: #{$mobile_max+1}px) {
  .wrapp-product {
    flex-wrap: wrap;
  }
  .item-image-purchases {
    width: 186px;
    height: 120px;
  }
  .item--basis {
    flex-basis: auto;
  }
  .item-main-purchases {
    flex-wrap: nowrap;
  }
  .item-main-purchases {
    flex-wrap: wrap;
    justify-content: center;
  }
  .item-data {
    width: 100%;
    flex-direction: column;
  }
  .childWrapper {
    flex-grow: 0;
    flex-basis: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .detailOrder {
    flex-direction: column;
  }

  .purchases {
    &-data {
      &--hide-field {
        display: none;
      }
    }
  }
}
@media (max-width: 570px) {
  .data {
    text-align: center;
    margin-bottom: 8px;
  }
  .purchases-row {
    flex-direction: column;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
  }
  .purchases-data {
    margin-bottom: 8px;
  }
}

@media (max-width: 490px) {
  .item-main-purchases {
    flex-wrap: wrap;
    justify-content: center;
  }
  .purchases-btn {
    width: 100%;
  }
}
