@import "../../../assets/scss/init";
.linkHome {
}
.wrapper {
  width: 100%;
  display: flex;
  flex-flow: row;
  padding: 20px;
}

.col-image {
  width: 20%;
  min-width: 160px;
  box-sizing: border-box;
  padding: 0 10px;
  flex-grow: 2;
  position: relative;
  img {
    width: 150px;
    height: 150px;
    max-width: 100%;
    object-fit: cover;
    border-radius: 100%;
  }
}

.col-info {
  box-sizing: border-box;
  padding: 0 10px;
  width: 40%;
}
.col-menu {
  box-sizing: border-box;
  padding: 0 10px;
  width: 40%;
}
.button-edit {
  display: inline-block;
  width: 160px;
}

.button-cancel {
  display: inline-block;
  background: white;
  border: 1px solid currentColor;
  width: 160px;
  span {
    color: currentColor;
  }
}

.name {
  font-family: SegoeUI;
  font-size: 26px;
  font-weight: $normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 26px;
  span {
    font-size: 12px;
    margin-left: 12px;
    cursor: pointer;
  }
}

.text {
  font-family: SegoeUI;
  font-size: 16px;
  font-weight: $normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: left;
  margin: 8px 0;
  color: #707070;
}

.menu-container {
  border-radius: 6px;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  border: 1px solid currentColor;
  background-color: rgba(245, 245, 245, 0.39);
}

.menu-item {
  span {
    cursor: pointer;
    font-family: SegoeUI;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: left;
    color: currentColor;
    margin-top: 4px;
    margin-bottom: 4px;
    display: inline-block;
    text-decoration: none;
    position: relative;

    &:after {
      bottom: 2px;
      width: 0%;
      left: 0;
      position: absolute;
      display: inline-block;
      content: "";
      height: 1px;
      background-color: currentColor;
      transition: width 0.5s;
    }
    &:hover:after {
      width: 100%;
    }
  }
  &--active span {
    font-weight: $bold;
  }
}

.img-edit {
  position: absolute;
  top: 5px;
  left: 15px;
  width: 140px;
  height: 140px;
  background: rgba(0, 0, 0, 1);
  border-radius: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  cursor: pointer;
  &__icon {
    font-size: 64px;
    color: #fff;
    transition: 0.3s;
    line-height: 1.5;
  }
  &__input {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  &:hover {
    opacity: 0.75;
    transition: 0.3s;
  }
  &:hover &__icon {
    transition: 0.3s;
    font-size: 72px;
  }
}

@media (max-width:#{$mobile-max}px) {
  .wrapper {
    width: 100%;
    display: flex;
    flex-flow: row;
    padding: 20px 20px;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .name {
    span {
      margin-left: 0;
      margin-top: 12px;
      display: block;
    }
  }

  .col-image {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 0px 20px;
    flex-grow: 2;
    img {
      width: 150px;
      height: 150px;
      max-width: 100%;
      object-fit: cover;
      border-radius: 100%;
    }
  }

  .col-info {
    box-sizing: border-box;
    padding: 0 0px 20px;
    width: 60%;
  }
  .col-menu {
    box-sizing: border-box;
    padding: 0 0px;
    width: 100%;
    text-align: left;
  }
  .menu-item {
    display: inline-block;
    margin-left: 12px;
    margin-right: 12px;
  }
  .menu-container {
    width: auto;
    display: inline-block;
  }
}
