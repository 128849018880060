@import "../../assets/scss/init";
.hackNotClick {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 15;
}
.product-button--disabled {
	background: #ffffff;
	border-radius: 6px;
	border: solid 1px #b2b2b2;
	padding: 12px 16px;
	color: #b2b2b2;
	text-transform: uppercase;
	display: block;
	margin: 12px auto;
	cursor: pointer;
	transition: background 0.5s;
	cursor: not-allowed;
	span {
		color: #b2b2b2;
	}
	&:hover {
		background-color: #ffffff;
		span {
			color: #b2b2b2;
		}
	}
}
.product-button--dirty {
	background: currentColor;
	border-radius: 6px;
	border: solid 1px currentColor;
	padding: 12px 16px;
	color: currentColor;
	text-transform: uppercase;
	display: block;
	margin: 12px auto;
	cursor: pointer;
	transition: background 0.5s;
	&:focus {
		outline: none;
	}
	span {
		color: #fff;
	}
}
.wrapped {
	display: flex;
}
.headerBasket {
	margin-right: 10px;
}
.mainLink {
	transition: 0.3s;
	margin-left: 0px;
	&:hover {
		border: 1px solid currentColor;
		border-radius: 6px;
		padding: 5px 11px 5px 10px;
		background: currentColor;
		span {
			color: #ffffff;
		}
	}
}
.displayBlockAll {
	display: flex;
	align-items: center;
	li {
		margin-right: 10px;
		&:hover {
			button {
				color: #000000 !important;
			}
		}
	}
}
.item-socialsAll {
	display: none;
}
.displayBlock {
	transform: translateX(30px);
	transition: 0.5s;
	position: absolute;
	top: 0;
	left: 6px;
	z-index: 1;
	background: #f5f5f5;
	border-radius: 6px;
	overflow: hidden;
	padding: 3px 0px;
	opacity: 1;
	width: auto;
	li {
		button {
			text-align: center;
			display: block;
			padding: 3px 8px !important;
			color: #c8cdd5 !important;
			text-decoration: none;
			font-size: 16px;
			outline: none;
			&:hover {
				color: currentColor !important;
			}
		}
	}
}
.item-socials {
	transition: transform 1s linear;
	transform: translateX(-50px);
	position: absolute;
	top: 0;
	left: 6px;
	z-index: 1;
	background: #f5f5f5;
	border-radius: 6px;
	overflow: hidden;
	padding: 3px 0px;
	opacity: 0;
	width: 0px;
	li {
		button {
			text-align: center;
			display: block;
			padding: 3px 8px !important;
			color: #c8cdd5 !important;
			text-decoration: none;
			font-size: 16px;
			outline: none;
		}
	}
}
.itemPriceMobile {
	padding-bottom: 10px;
}
.detailOrder {
	display: flex;
	flex-wrap: wrap;
	margin: 5px;
}
.detailsTitle {
	margin-bottom: 5px;
}
.childDetails {
	flex-grow: 1;
	flex-basis: 230px;
}
.item-order {
	margin-left: 3%;
}
.layout {
	display: flex;
	justify-content: space-between;
}

.col_left {
	width: 66%;
}

.col_right {
	width: 33%;
}

.container {
	margin-top: 36px;
}
.item {
	display: block;
	border-radius: 6px;
	background-color: #ffffff;
	box-sizing: border-box;
	padding: 10px;
	margin-bottom: 10px;
	cursor: pointer;
	&:hover {
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
		transition: 0.3s;
	}

	&-main {
		display: flex;
		flex-direction: row;
	}

	&-icon {
		margin-right: 5px;
		width: 25px;
		height: 25px;
		background-color: #c8cdd5;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		margin-bottom: 5px;
		cursor: pointer;
		transition: background-color 0.5s;
		span,
		svg {
			font-size: 15px;
			color: #fff;
			font-weight: bold;
			line-height: 1;
		}
		&:hover {
			box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
			transition: 0.3s;
			background-color: currentColor;
			transform: scale(1.2);
		}
	}
	//extra button for remove etc
	&-include {
		background-color: currentColor;
	}
	&-red {
		&:hover {
			background-color: red;
			transform: scale(1.2);
		}
	}
	&-heart {
		&:hover {
			color: red;
			background-color: currentColor;
			transform: scale(1.2);
		}
		i {
			font-size: 20px;
			top: 2px;
			color: #ffffff;
		}
	}
	&-image {
		display: block;
		width: 186px;
		height: 120px;
		object-fit: cover;
		border-radius: 6px;
		margin-left: 5px;
		margin-right: 5px;
	}
	&-title {
		font-family: SegoeUI;
		font-size: 18px;
		font-weight: $semi-bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		text-align: left;
		color: #010101;
		margin-top: 10px;
		margin-bottom: 4px;
	}

	&-data {
		margin-left: 5px;
	}

	&-summary {
		font-family: SegoeUI;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: normal;
		text-align: left;
		color: #707070;

		li > span {
			font-size: 13px;
		}
	}

	&-price {
		font-family: SegoeUI;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.36;
		letter-spacing: normal;
		text-align: left;
		color: #000000;
		margin-top: 20px;
	}

	&-toggle {
		margin-left: 12px;
		user-select: none;
		label {
			cursor: pointer;
			color: #000000;
			border-bottom: 1px dashed currentColor;
			&:before {
				content: "❬";
				transform: scaleX(0.5) rotate(-90deg);
				display: inline-block;
				margin-right: 4px;
				transition: transform 0.5s;
			}
		}
		input {
			display: none;
		}
		input:checked + label {
			color: currentColor;
			&:before {
				transform: scaleX(0.5) rotate(90deg);
				transition: transform 0.5s;
			}
		}
		&-activeLabel {
			color: currentColor !important;
			&:before {
				transform: scaleX(0.5) rotate(90deg) !important;
				transition: transform 0.5s !important;
			}
		}
	}
	&-details {
		overflow: hidden;
		max-height: 0;
		transition: max-height 0.5s;

		&--expanded {
			max-height: 9999px;
			transition: max-height 0.5s;
		}
	}
}
.like-on {
	color: red !important;
}
.product {
	margin-top: 16px;
	margin-left: 36px;
	margin-right: 30px;
	box-sizing: border-box;
	padding: 16px 0px;
	border-top: 3px solid #f3f5fa;
	position: relative;
	&-title {
		font-family: SegoeUI;
		font-size: 16px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.31;
		letter-spacing: normal;
		text-align: left;
		color: #1d1d1d;
		margin-top: 16px;
	}
	&-text {
		font-family: SegoeUI;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		text-align: left;
		color: #707070;
	}
	&-additional {
		transition: 0.3s;
	}

	&-quantity {
		box-sizing: border-box;
		padding: 2px 5px;
		display: inline-block;
		font-size: 14px;
		text-decoration: underline;
		font-weight: $bold;
		input {
			cursor: pointer;
			padding: 5px;
			border-radius: 6px;
			border: 1px solid #ccc;
		}
		&-btn {
			background: none;
			border: none;
			display: inline-block;
			padding: 5px 10px;
			font-weight: $bold;
			cursor: pointer;
			&:active,
			&:focus {
				outline: none;
			}
			&--done {
				span,
				svg {
					font-size: 16px;
					color: lighten(green, 10%);
				}
			}
		}
	}

	&-toggler {
		user-select: none;
		display: block;
		color: #666;
		font-style: italic;
		margin-top: 12px;
		font-size: 14px;
		margin-left: -3px;
		font-weight: $normal;
	}
	&-param {
		display: inline-block;
		box-sizing: border-box;
		width: 50%;
		padding: 10px 5px;
	}
	&-param-caption {
		font-family: SegoeUI;
		font-size: 14px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.36;
		letter-spacing: normal;
		text-align: left;
		color: #1d1d1d;
	}
	&-param-value {
		font-family: SegoeUI;
		font-size: 14px;
		display: inline-block;
		margin-left: 12px;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.36;
		letter-spacing: normal;
		text-align: left;

		font-weight: $light;
		color: #707070;
	}

	&-param-input {
		background: transparent;
		margin-top: 12px;
		display: block;
		width: 100%;
		background: none;
		color: #707070;
		border: 1px solid #d6d4d6;
		border-radius: 6px;
		box-sizing: border-box;
		padding: 6px;
		font-family: SegoeUI;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: italic;
		line-height: 1.36;
		letter-spacing: normal;
		text-align: left;
	}
	&-remove {
		position: absolute;
		right: 0;
		top: 10px;
		cursor: pointer;
		span,
		svg {
			color: #c8cdd5;
			transition: color 0.5s;
			&:hover {
				color: desaturate(red, 30%);
				transition: color 0.5s;
			}
		}
	}
	&-duplicate {
		position: absolute;
		right: 32px;
		top: 11px;
		cursor: pointer;
		span,
		svg {
			color: #c8cdd5;
			transition: color 0.5s;
			&:hover {
				color: lighten(green, 10%);
				transition: color 0.5s;
			}
		}
	}

	&-button {
		background: #fff;
		border-radius: 6px;
		border: solid 1px currentColor;
		padding: 12px 16px;
		color: currentColor;
		text-transform: uppercase;
		display: block;
		margin: 12px auto;
		cursor: pointer;
		transition: background 0.5s;
		&:focus {
			outline: none;
		}
		&:hover {
			background: currentColor;
			transition: background 0.5s;
			span {
				color: #fff;
			}
		}
	}
}
.product-param-input--disabled {
	background: #f3f5fa !important;
	margin-top: 12px;
	display: block;
	width: 100%;
	background: none;
	color: #707070;
	border: 1px solid #d6d4d6;
	border-radius: 6px;
	box-sizing: border-box;
	padding: 6px;
	font-family: SegoeUI;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: italic;
	line-height: 1.36;
	letter-spacing: normal;
	text-align: left;
}
@media (max-width:#{$mobile_max}px) {
	.layout {
		display: flex;
		flex-flow: column;
		justify-content: space-between;
	}

	.col_left {
		width: 100%;
	}

	.col_right {
		width: 100%;
	}

	.container {
		margin-top: 6px;
	}

	.item {
		display: block;
		border-radius: 6px;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
		background-color: #ffffff;
		box-sizing: border-box;
		padding: 10px;
		margin-bottom: 10px;

		&-main {
			display: flex;
			flex-direction: row;
		}

		&-icon {
			margin-right: 5px;
			width: 20px;
			height: 20px;
			background-color: #c8cdd5;
			display: flex;

			align-items: center;
			justify-content: center;
			border-radius: 100%;
			margin-bottom: 5px;
			cursor: pointer;
			transition: background-color 0.5s;
			span,
			svg {
				font-size: 12px;
				color: #fff;
				font-weight: bold;
				line-height: 1;
			}
			&:hover {
				background-color: currentColor;
			}
		}
		&-image {
			display: block;
			width: 48px;
			height: 48px;
			object-fit: cover;
			border-radius: 6px;
			margin-left: 5px;
			margin-right: 5px;
		}
		&-title {
			font-family: SegoeUI;
			font-size: 18px;
			font-weight: $semi-bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			text-align: left;
			color: #010101;
			margin-top: 10px;
			margin-bottom: 4px;
		}

		&-data {
			margin-left: 5px;
		}

		&-summary {
			font-family: SegoeUI;
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.5;
			letter-spacing: normal;
			text-align: left;
			color: #707070;

			li span {
				font-size: 13px;
			}
		}

		&-price {
			font-family: SegoeUI;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.36;
			letter-spacing: normal;
			text-align: left;
			color: #000000;
			margin-top: 20px;
		}

		&-toggle {
			margin-left: 12px;
			user-select: none;
			transition: 0.8s;
			label {
				cursor: pointer;
				color: #000000;
				border-bottom: 1px dashed currentColor;
				&:before {
					content: "❬";
					transform: scaleX(0.5) rotate(-90deg);
					display: inline-block;
					margin-right: 4px;
					transition: transform 0.8s;
				}
			}
			// input {
			//   display: none;
			// }
			// input:checked + label {
			//   color: currentColor;
			//   &:before {
			//     transform: scaleX(0.5) rotate(90deg);
			//     transition: transform 0.5s;
			//   }
			// }
		}
		&-details {
			overflow: hidden;
			max-height: 0;
			transition: max-height 0.5s;

			&--expanded {
				max-height: 9999px;
				transition: max-height 0.5s;
			}
		}
	}
	.activeLabel {
		label {
			color: currentColor;
			&:before {
				transform: scaleX(0.5) rotate(90deg);
				transition: transform 0.5s;
			}
		}
	}
	.product {
		margin-top: 16px;
		margin-left: 6px;
		margin-right: 6px;
		box-sizing: border-box;
		padding: 16px 0px;
		border-top: 3px solid #f3f5fa;
		position: relative;
		&-title {
			font-family: SegoeUI;
			font-size: 16px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.31;
			letter-spacing: normal;
			text-align: left;
			color: #1d1d1d;
			margin-top: 16px;
		}
		&-text {
			font-family: SegoeUI;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			text-align: left;
			color: #707070;
		}
		&-param {
			display: inline-block;
			box-sizing: border-box;
			width: 100%;
			padding: 10px 5px;
		}
		&-param-caption {
			font-family: SegoeUI;
			font-size: 14px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.36;
			letter-spacing: normal;
			text-align: left;
			color: #1d1d1d;
		}
		&-param-value {
			font-family: SegoeUI;
			font-size: 14px;
			display: inline-block;
			margin-left: 12px;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.36;
			letter-spacing: normal;
			text-align: left;

			font-weight: $light;
			color: #707070;
		}

		&-param-input {
			margin-top: 12px;
			display: block;
			width: 100%;
			background: none;
			color: #707070;
			border: 1px solid #d6d4d6;
			border-radius: 6px;
			box-sizing: border-box;
			padding: 6px;
			font-family: SegoeUI;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: italic;
			line-height: 1.36;
			letter-spacing: normal;
			text-align: left;
		}
		&-remove {
			position: absolute;
			right: 0;
			top: 10px;
			cursor: pointer;
			span,
			svg {
				color: #c8cdd5;
				transition: color 0.5s;
				&:hover {
					color: desaturate(red, 30%);
					transition: color 0.5s;
				}
			}
		}

		&-button {
			background: #fff;
			border-radius: 6px;
			border: solid 1px currentColor;
			padding: 12px 16px;
			color: currentColor;
			text-transform: uppercase;
			display: block;
			margin: 12px auto;
			cursor: pointer;
			transition: background 0.5s;
			&:focus {
				outline: none;
			}
			&:hover {
				background: currentColor;
				transition: background 0.5s;
				span {
					color: #fff;
				}
			}
		}
	}
	.product-button--dirty {
		background: currentColor;
		border-radius: 6px;
		border: solid 1px currentColor;
		padding: 12px 16px;
		color: currentColor;
		text-transform: uppercase;
		display: block;
		margin: 12px auto;
		cursor: pointer;
		transition: background 0.5s;
		&:focus {
			outline: none;
		}
		&:hover {
			background: currentColor;
			transition: background 0.5s;
			span,
			svg {
				color: #fff;
			}
		}
	}
}
@media (max-width: 400px) {
	.itemPriceMobile {
		display: flex;
		flex-wrap: wrap;
		span {
			margin-left: 0px;
			margin-top: 5px;
			width: 100%;
		}
	}
}
