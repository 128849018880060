@import "../../../assets/scss/init";
@import "./Reviews.buttons.module.scss";
.form {
	position: relative;
	width: 100%;
}

.slider-group {
	width: 100%;
	display: flex;
	box-sizing: border-box;
	padding-top: 7.5px;
	padding-bottom: 7.5px;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.slider-container {
	width: calc(100% - 180px);
}
.slider-title {
	display: block;
	width: 110px;
	word-wrap: break-word;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	text-transform: capitalize;
	color: #666666;
}

.slider-value {
	display: block;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	text-transform: capitalize;
	color: #666666;
	width: 20px;
}

.close {
	cursor: pointer;
	position: absolute;
	right: -40px;
	top: 0px;
	color: rgba(0, 0, 0, 0.5);
	font-size: 20px;
	font-weight: 100;
}

.section {
	margin-bottom: 46px;
	width: 100%;
}
.form-btn {
	margin-top: 21px;
}

.title {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 32px;
	text-transform: uppercase;
	color: #000;
	margin-bottom: 24px;
}

.label {
	margin-left: 21px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;
	color: rgba(0, 0, 0, 0.5);
	z-index: 1;
	background: #fff;
	padding: 5px;
	box-sizing: border-box;
	display: inline-block;
}
.input,
.textarea {
	width: 100%;
	padding: 21px;
	display: block;
	position: relative;
	top: -15px;
	margin-bottom: -6px;
	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: 6px;
	&:focus {
		border: 1px solid #000;
		outline: none;
	}
}

@media (max-width:#{$tablet_max}px) {
	.close {
		right: 0px;
		top: 0px;
		z-index: 2;
	}

	.input,
	.textarea {
		width: calc(100% - 40px);
		padding: 21px;
	}
}

@media (max-width:#{$mobile_max}px) {
	.form-btn {
		width: 100%;
		display: block;
	}
}
@media (max-width: 540px) {
	.slider-group {
		width: 100%;
		display: flex;
		box-sizing: border-box;
		padding-top: 7.5px;
		padding-bottom: 7.5px;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.slider-container {
		order: 3;
		width: 100%;
	}
	.slider-title {
		order: 1;
		display: block;
		width: calc(100% - 30px);
		word-wrap: break-word;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 19px;
		text-transform: capitalize;
		color: #666666;
	}

	.slider-value {
		order: 2;
		display: block;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 19px;
		text-transform: capitalize;
		color: #666666;
		width: 20px;
	}
}
