@import "../../../assets/scss/init";

@media (min-width:#{$mobile-max+1}px) {
	.review {
		display: flex;
		flex-direction: row;
		padding-top: 28px;
		padding-bottom: 28px;
		padding-right: 32px;
	}
	.img {
		width: 32px;
		height: 32px;
		border-radius: 32px;
		display: block;
		box-sizing: border-box;
		margin-right: 26px;
	}
	.text {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 15px;
		letter-spacing: normal;
		text-align: left;
		color: #707070;
	}

	.label {
		margin-right: 16px;
		display: inline-block;
	}
	.heading {
		font-size: 16px;
		font-weight: 600;
		color: #4e5255;
		margin-bottom: 11px;
	}

	.button {
		display: inline-block;
		border-radius: 6px;
		cursor: pointer;
		outline: none;
		border-width: 1px;
		border-style: solid;
		padding-top: 10px;
		padding-bottom: 10px;
		font-family: inherit;
		width: 125px;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		line-height: 13px;
		text-transform: uppercase;
		text-align: center;
		&:disabled {
			cursor: default;
		}
		&-more {
			margin-right: 17px;
			margin-left: 56px;
			border-color: rgba(0, 0, 0, 0.4);
			color: rgba(0, 0, 0, 0.4);
			background-color: #fff;
			transition: 0.5s;
			&:hover {
				border-color: currentColor;
				color: currentColor;
			}
		}
		&-post {
			border-color: currentColor;
			color: currentColor;
			background-color: #fff;
			transition: 0.5s;
			&:hover {
				background-color: currentColor;
				span {
					color: #fff;
				}
			}
			&:disabled {
				border-color: rgba(0, 0, 0, 0.2);
				color: rgba(0, 0, 0, 0.2);
				&:hover {
					background-color: #fff;
					span {
						color: rgba(0, 0, 0, 0.2);
					}
				}
			}
		}
	}
}

@media (max-width:#{$mobile-max}px) {
	.review {
		display: flex;
		flex-direction: row;
		padding-top: 28px;
		padding-bottom: 28px;
		padding-right: 32px;
	}
	.img {
		width: 32px;
		height: 32px;
		border-radius: 32px;
		display: block;
		box-sizing: border-box;
		margin-right: 26px;
	}
	.text {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 15px;
		letter-spacing: normal;
		text-align: left;
		color: #707070;
	}

	.label {
		margin-right: 16px;
		display: inline-block;
	}
	.heading {
		font-size: 16px;
		font-weight: 600;
		color: #4e5255;
		margin-bottom: 11px;
	}

	.button {
		display: block;
		border-radius: 6px;
		cursor: pointer;
		outline: none;
		border-width: 1px;
		border-style: solid;
		padding-top: 10px;
		padding-bottom: 10px;
		font-family: inherit;
		width: 90%;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		line-height: 13px;
		text-transform: uppercase;
		text-align: center;
		margin-top: 10px;
		margin-bottom: 20px;
		&:disabled {
			cursor: default;
		}
		&-more {
			border-color: rgba(0, 0, 0, 0.4);
			box-sizing: border-box;
			color: rgba(0, 0, 0, 0.4);
			background-color: #fff;
			transition: 0.5s;
			&:hover {
				border-color: currentColor;
				color: currentColor;
			}
		}
		&-post {
			border-color: currentColor;
			color: currentColor;
			background-color: #fff;
			transition: 0.5s;
			&:hover {
				background-color: currentColor;
				span {
					color: #fff;
				}
			}
		}
	}
}
