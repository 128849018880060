@import "../../../assets/scss/init";

.btn-category {
    margin-right: 5px;
    margin-bottom: 5px;
    text-transform: uppercase;
    border-radius: 4px;
    border: solid 1px #d4d8e1;
    background-color: #d4d8e1;
    font-family: SegoeUI;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #798493;
    padding: 12px 24px;
    cursor: pointer;
    flex-grow: 1;
    transition: background-color 0.3s;
    &:focus {
        outline: none;
    }
    &:hover {
        background-color: darken(#d4d8e1, 5%);
    }
    &--on {
        object-fit: contain;
        border-radius: 4px;
        background-color: #798493;
        font-family: SegoeUI;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: #dfdfdf;
        &:hover {
            background-color: lighten(#798493, 5%);
        }
    }
}
// .wrapper-btn {
//   display: flex;
//   justify-content: space-around;
// }

@media (min-width:#{$mobile_max+1}px) {
    .hideForClick {
        z-index: -1;
    }
    .filter {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        &-title {
            font-family: SegoeUI;
            font-size: 24px;
            font-weight: $semi-bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.83;
            letter-spacing: normal;
            text-align: left;
            color: #202124;
            text-transform: uppercase;
            margin-right: 25px;
            text-decoration: none;
            padding-bottom: 5px;
            transition: 0.3s;
            border-bottom: 1px solid rgba(0, 0, 0, 0);
            display: inline-block;
        }
        &-group {
            margin: 5px 5px;
            border-radius: 6px;
            border: solid 1px #d4d8e1;
            box-sizing: border-box;
            padding: 2px 10px 6px 10px;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            flex-grow: 1;
            cursor: pointer;
            // justify-content: space-between;
            align-items: center;
            &:hover {
                // background-color: #ffffff;
                border: 1px solid currentColor;
            }
            & > i,
            div {
                margin: 0 5px;
            }
        }
        &-text {
            height: 21px;
            margin: 0;
            background: none;
            font-family: SegoeUI;
            font-size: 14px;
            font-weight: $normal;
            cursor: pointer;
            display: block;
            color: #000000;
        }

        &-button {
            margin: 0 5px;
            background: none;
            height: 42px;
            cursor: pointer;
            display: block;
            border-radius: 6px;
            border: solid 1px #d4d8e1;
            background-color: #d4d8e1;
            box-sizing: border-box;
            padding: 2px 10px 6px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            &:focus {
                outline: none;
            }
        }

        &-icon {
            font-size: 22px;
            color: #aaa;
        }

        &-label {
            display: block;
            font-size: 12px;
            font-weight: $light;
            color: #9d9d9d;
        }

        &-input {
            width: 70px;
            outline: none;
            border: none;
            background-color: rgba(255, 255, 255, 0);
            font-family: SegoeUI;
            font-size: 14px;
            font-weight: $normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.07;
            letter-spacing: normal;
            text-align: left;
            color: #000000;
        }
        &-select {
            width: 90px;
            outline: none;
            border: none;
            background-color: rgba(255, 255, 255, 0);
            font-family: SegoeUI;
            font-size: 14px;
            font-weight: $normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.07;
            letter-spacing: normal;
            text-align: left;
            color: #000000;
        }
        &-btn {
            height: 46px;
            background-color: inherit;
            display: inline-block;
            box-sizing: border-box;
            color: currentColor;
            outline: none;
            border: 1px solid currentColor;
            padding: 2px 10px 2px 10px;
            border-radius: 6px;
            text-transform: uppercase;
            font-family: SegoeUI;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            flex-grow: 1;
            letter-spacing: normal;
            text-align: center;
            cursor: pointer;
            transition: 0.3s;
            opacity: 0.8;
            text-decoration: none;
            margin-left: 5px;
            flex-grow: 1;
            &:hover {
                transition: 0.3s;
                background-color: currentColor;
                color: #fff;
                span {
                    transition: 0.3s;
                    color: #fff;
                }
            }
            span {
                color: currentColor;
                text-transform: uppercase;
                height: 21px;
            }
        }
    }
}
@media (max-width: 1027px) {
    .filter-title {
        width: 100%;
        margin-top: 8px;
    }
}
@media (max-width:#{$mobile_max}px) {
    .hideForClick {
        z-index: -1;
    }
    .btn-category {
        margin-right: 5px;
        margin-bottom: 5px;
        text-transform: uppercase;
        border-radius: 4px;
        border: solid 1px #d4d8e1;
        background-color: #d4d8e1;
        font-family: SegoeUI;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: #798493;
        padding: 12px 12px;
        cursor: pointer;
        flex-grow: 1;
        &:focus {
            outline: none;
        }
        // transition: box-shadow 0.3s;
        // &:hover {
        //   box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
        // }
        &--on {
            object-fit: contain;
            border-radius: 4px;
            background-color: #798493;
            font-family: SegoeUI;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: center;
            color: #dfdfdf;
        }
    }
    .filter {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;

        &-title {
            display: none;
        }
        &-button {
            margin: 0 5px;
            background: none;
            height: 42px;
            cursor: pointer;
            display: block;
            border-radius: 6px;
            border: solid 1px #d4d8e1;
            background-color: #d4d8e1;
            box-sizing: border-box;
            padding: 2px 10px 6px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
        }
        &-text {
            margin: 0;
            background: none;
            font-family: SegoeUI;
            font-size: 14px;
            font-weight: $normal;
            cursor: pointer;
            display: block;
            color: #000000;
        }
        &-group {
            margin: 5px 5px;
            border-radius: 6px;
            border: solid 1px #d4d8e1;
            box-sizing: border-box;
            padding: 2px 10px 6px 10px;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            flex-grow: 1;
            cursor: pointer;
            // justify-content: flex-start;
            align-items: center;

            &:hover {
                // background-color: #ffffff;

                border: 1px solid currentColor;

                cursor: pointer;
            }
            & > i,
            div {
                margin: 0 5px;
            }
            width: auto;
        }

        &-icon {
            font-size: 22px;
            color: #d4d8e1;
        }

        &-label {
            display: block;
            font-size: 12px;
            font-weight: $light;
            color: #9d9d9d;
        }

        &-input {
            width: 70px;
            outline: none;
            border: none;
            background-color: rgba(255, 255, 255, 0);
            font-family: SegoeUI;
            font-size: 14px;
            font-weight: $normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.07;
            letter-spacing: normal;
            text-align: left;
            color: #000000;
        }
        &-select {
            width: auto;
            outline: none;
            border: none;
            background-color: rgba(255, 255, 255, 0);
            font-family: SegoeUI;
            font-size: 14px;
            font-weight: $normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.07;
            letter-spacing: normal;
            text-align: left;
            color: #000000;
        }
        &-btn {
            height: 46px;
            background-color: inherit;
            display: inline-block;
            box-sizing: border-box;
            color: currentColor;
            outline: none;
            border: 1px solid currentColor;
            padding: 2px 10px 2px 10px;
            border-radius: 6px;
            text-transform: uppercase;
            font-family: SegoeUI;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            flex-grow: 1;
            cursor: pointer;
            transition: 0.3s;
            opacity: 0.8;
            text-decoration: none;
            margin-left: 5px;
            &:hover {
                transition: 0.3s;
                background-color: currentColor;
                color: #fff;
                span {
                    transition: 0.3s;
                    color: #fff;
                }
            }
            span {
                color: currentColor;
                text-transform: uppercase;
                height: 21px;
            }
        }
    }
}
@media (max-width: 460px) {
    .filter-btn {
        width: 100%;
        margin: 3px 0px 0px 0px;
    }
}

@media (max-width:#{$mobile_small_max}px) {
    .btn-category {
        margin-right: 5px;
        margin-bottom: 5px;
        text-transform: uppercase;
        border-radius: 4px;
        border: solid 1px #d4d8e1;
        background-color: #d4d8e1;
        font-family: SegoeUI;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: #798493;
        padding: 12px 12px;
        flex-grow: 1;
        cursor: pointer;
        &:focus {
            outline: none;
        }
        // transition: box-shadow 0.3s;
        // &:hover {
        //   box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
        // }
        &--on {
            object-fit: contain;
            border-radius: 4px;
            background-color: #798493;
            font-family: SegoeUI;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: center;
            color: #dfdfdf;
        }
    }
    .filter {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        // background-color: #f6f8f9;
        // transform: translateY(-92px);
        &-button {
            margin: 0 5px;
            background: none;
            height: 42px;
            cursor: pointer;
            display: block;
            border-radius: 6px;
            border: solid 1px #d4d8e1;
            background-color: #d4d8e1;
            box-sizing: border-box;
            padding: 2px 10px 6px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            width: 100%;
        }
        &-text {
            margin: 0;
            background: none;
            font-family: SegoeUI;
            font-size: 14px;
            font-weight: $normal;
            cursor: pointer;
            display: block;
            color: #000000;
        }
        &-group {
            margin: 5px 5px;
            border-radius: 6px;
            border: solid 1px #d4d8e1;
            box-sizing: border-box;
            padding: 2px 10px 6px 10px;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            cursor: pointer;
            // justify-content: flex-start;
            align-items: center;
            flex-grow: 1;
            & > i,
            div {
                margin: 0 5px;
            }
            width: 100%;
            &:hover {
                background-color: #ffffff;
                border: 1px solid currentColor;
            }
        }

        &-icon {
            font-size: 22px;
            color: #d4d8e1;
        }

        &-label {
            display: block;
            font-size: 12px;
            font-weight: $light;
            color: #9d9d9d;
        }

        &-input {
            width: 70px;
            outline: none;
            border: none;
            background-color: rgba(255, 255, 255, 0);
            font-family: SegoeUI;
            font-size: 14px;
            font-weight: $normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.07;
            letter-spacing: normal;
            text-align: left;
            color: #000000;
        }
        &-select {
            width: 100%;
            outline: none;
            border: none;
            background-color: rgba(255, 255, 255, 0);
            font-family: SegoeUI;
            font-size: 14px;
            font-weight: $normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.07;
            letter-spacing: normal;
            text-align: left;
            color: #000000;
        }
        &-btn {
            height: 46px;
            background-color: inherit;
            display: inline-block;
            box-sizing: border-box;
            color: currentColor;
            outline: none;
            border: 1px solid currentColor;
            padding: 2px 10px 2px 10px;
            border-radius: 6px;
            text-transform: uppercase;
            font-family: SegoeUI;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            flex-grow: 1;
            cursor: pointer;
            transition: 0.3s;
            opacity: 0.8;
            text-decoration: none;
            margin-left: 5px;
            &:hover {
                transition: 0.3s;
                background-color: currentColor;
                color: #fff;
                span {
                    transition: 0.3s;
                    color: #fff;
                }
            }
            span {
                color: currentColor;
                text-transform: uppercase;
                height: 21px;
            }
        }
    }
}
