.emergencyText {
  text-align: center;
  padding: 10px;
}
.container {
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 20px 5px;
  margin-top: 36px;
}
.title {
  font-family: SegoeUI;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: currentColor;
  margin-bottom: 12px;
}

.item {
  background-color: #f3f5fa;
  border-radius: 6px;
  padding: 10px 15px 22px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;

  &-name {
    width: 50%;
    font-family: SegoeUI;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #616161;
  }

  &-price {
    width: 25%;
    font-family: SegoeUI;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: right;
    color: #707070;
  }
}

.total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 8px;
  margin-right: 8px;
  &-caption {
    font-family: SegoeUI;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    color: currentColor;
    text-align: left;
  }

  &-price {
    font-family: SegoeUI;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    color: currentColor;
    text-align: right;
  }

  &-hint {
    font-family: SegoeUI;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    display: block;
    border-bottom: 1px dashed #707070;
  }
}

.text {
  margin-top: 64px;
  margin-bottom: 16px;
  text-align: center;
  font-family: SegoeUI;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #616161;
}
