@import "../../../assets/scss/init.scss";

.hours-item {
  border-radius: 6px;
  border: solid 1px #eaecf0;
  background-color: #ffffff;
  font-family: SegoeUI;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: $normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #333;
  display: block;
  outline: none;
  padding: 10px;
  width: 44px;
  transition: 0.3s;
  cursor: pointer;
  margin: 5px !important;
  box-sizing: border-box;
  box-shadow: 2px 3px 5px #e6e6e6;
  &:hover {
    transition: 0.3s;
    color: #ffffff;
    background-color: #3296cb;
    box-shadow: 0 0 10px darken(#ffffff, 10%);
  }
}
.active {
  color: #ffffff;
  background-color: #3296cb;
  box-shadow: 0 0 10px darken(#ffffff, 10%);
}
.category {
  padding: 15px 20px;
  width: auto;
}
// {
//   border: 1px solid #cccccc;
//   padding: 0.4em;
//   width: 35px;
//   text-align: center;
//   border-radius: 4px;
//   margin: 0.2em;
// }
