@import "../../assets/scss/init.scss";
.bannerText {
  display: block;
  padding-left: 33px;
  padding-right: 20px;
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #ffffff;
}
.containerBanner {
  line-height: normal;
}
.mobileBunner {
  display: block;
  text-decoration: none;
}
.bannerData {
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  width: 300px;
  padding-top: 20px;
  box-sizing: border-box;
  height: 100%;
}
.bannerButton {
  margin: 16px 0;
  height: 46px;
  width: 120px;

  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: #ffffff;
  outline: none;
  border: 1px solid #ffffff;
  padding: 2px 10px 2px 10px;
  border-radius: 6px;
  text-transform: uppercase;
  font-family: SegoeUI;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;

  text-decoration: none;

  &:hover {
    transition: 0.3s;
    background-color: currentColor;
    span {
      color: #ffffff;
    }
  }
  span {
    color: #ffffff;
    text-transform: uppercase;
  }
}
@media (max-width:#{$mobile_max+1}px) {
  .mobileBunner {
    background-size: cover !important;
  }
}
