$icomoon-font-family: "ut-iconic" !default;
$icomoon-font-path: "fonts" !default;

$ut-ico-more: "\e928";
$ut-ico-cart: "\e92b";
$ut-ico-calendar: "\e92f";
$ut-ico-star-full: "\e926";
$ut-ico-rate: "\e926";
$ut-ico-star: "\e926";
$ut-ico-favorite: "\e926";
$ut-ico-bookmark: "\e926";
$ut-ico-search: "\e927";
$ut-ico-magnifier: "\e927";
$ut-ico-magnifying-glass: "\e927";
$ut-ico-inspect: "\e927";
$ut-ico-find: "\e927";
$ut-ico-sales: "\e925";
$ut-ico-home: "\e929";
$ut-ico-guest: "\e92a";
$ut-ico-clock: "\e92c";
$ut-ico-calendar2: "\e92d";
$ut-ico-calendar1: "\e92e";
$ut-ico-2: "\e900";
$ut-ico-18: "\e901";
$ut-ico-ac: "\e902";
$ut-ico-admittance_free: "\e903";
$ut-ico-alcohol: "\e904";
$ut-ico-all_year: "\e905";
$ut-ico-banquets: "\e906";
$ut-ico-beauty: "\e907";
$ut-ico-card_payment: "\e908";
$ut-ico-childrens_corner: "\e909";
$ut-ico-cognitive: "\e90a";
$ut-ico-conference_hall: "\e90b";
$ut-ico-dance_floor: "\e90c";
$ut-ico-family: "\e90d";
$ut-ico-game_console: "\e90e";
$ut-ico-gym: "\e90f";
$ut-ico-hookah: "\e910";
$ut-ico-karaoke: "\e911";
$ut-ico-kids: "\e912";
$ut-ico-kids_club: "\e913";
$ut-ico-leisure_1: "\e914";
$ut-ico-leisure_2: "\e915";
$ut-ico-music: "\e916";
$ut-ico-parking: "\e917";
$ut-ico-pets: "\e918";
$ut-ico-relax: "\e919";
$ut-ico-room-service1: "\e91a";
$ut-ico-smoke: "\e91b";
$ut-ico-spa: "\e91c";
$ut-ico-sports_broadcasts: "\e91d";
$ut-ico-summer_terrace: "\e91e";
$ut-ico-table_reservation: "\e91f";
$ut-ico-takeaway: "\e920";
$ut-ico-view1: "\e921";
$ut-ico-vine_map: "\e922";
$ut-ico-vip: "\e923";
$ut-ico-wifi1: "\e924";

