@import "../../../assets/scss/init.scss";

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  box-sizing: border-box;
  padding: 30px 10px;
  margin-bottom: 10px;
  max-width: 600px;
}
.time-column {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  margin-top: 20px;
}
.time-column-more {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.extraMore {
  border-left: 1px solid lightgrey;
}
.extra {
  border-left: 1px solid lightgrey;
  margin-left: 7px;
  margin-right: 15px;
  padding-right: 20px;
}
.withoutPadding {
  padding-left: unset;
  margin-left: 56px;
}
.container-hours-more {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}

.wrap-time {
  display: flex;
  padding-bottom: 28px;
  border-bottom: 1px solid lightgrey;
  // margin-bottom: 16px;
}
.wrap-time-category {
  display: flex;
  padding-bottom: 28px;
}

.hours-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.hours-wrapper-more {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  align-self: center;
  justify-content: center;
}
// .child-wrapper {
//   display: flex;
//   flex-wrap: wrap;
// }
.time-header {
  color: currentColor;
  text-transform: uppercase;
  font-family: SegoeUI;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 16px;
  white-space: nowrap;
}

.time-header-more-filter {
  color: currentColor;
  text-transform: uppercase;
  font-family: SegoeUI;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  padding-bottom: 16px;
  white-space: nowrap;
}
.actions-button {
  background-color: #fff;
  display: inline-block;
  box-sizing: border-box;
  box-shadow: 2px 3px 5px #e6e6e6;
  color: currentColor;
  outline: none;
  border: 1px solid currentColor;
  padding: 13px 28px;
  border-radius: 3px;
  text-transform: uppercase;
  font-family: SegoeUI;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;

  cursor: pointer;
  transition: 0.3s;
  opacity: 0.8;
  text-decoration: none;
  margin-top: 30px;
  &:hover {
    transition: 0.3s;
    background-color: currentColor;
    color: #fff;
    span {
      transition: 0.3s;
      color: #fff;
    }
  }
  span {
    color: currentColor;
    text-transform: uppercase;
  }
}
.chart-container-action {
  padding-top: 12px;
}
.wrapper-category {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (max-width:#{$mobile-max}px) {
  // .container {
  //   max-width: 400px;
  // }
  .wrap-time {
    flex-wrap: wrap;
  }
  .time-column {
    padding-left: unset;
  }
  .time-column-more {
    // display: flex;
    // flex-direction: column;
    width: 100%;
  }
  .container-hours {
    padding-bottom: 16px;
  }
  // .hours-wrapper {
  //   justify-content: space-between;
  // }
  .extra {
    border: none;
    margin-top: 28px;
  }
  .extraMore {
    border-left: none;
    padding-top: 16px;
  }
}
