@import "../../../assets/scss/init";
.container {
    padding: 0 20px 30px;
}
.title {
    font-family: SegoeUI;
    font-size: 24px;
    font-weight: $semi-bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
    letter-spacing: normal;
    text-align: center;
    margin-top: 2em;
    margin-bottom: 1em;
    padding-bottom: 1em;
    box-sizing: border-box;
    color: #202124;
    text-transform: uppercase;
    text-decoration: none;
    border-bottom: 1px solid #ddd;
}

.location-char {
    font-size: 20px;
    font-weight: $bold;
}

.location-item {
    list-style-type: none;
    padding-left: 12px;
    font-size: 16px;
    display: block;
    font-weight: $normal;
    margin-bottom: 6px;
    margin-top: 6px;
    color: #333;
    padding: 2px;
    cursor: pointer;
}
