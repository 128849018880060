@import "../../assets/scss/init";

.title {
  font-family: SegoeUI;
  font-size: 24px;
  font-weight: $semi-bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: normal;
  text-align: center;
  margin-top: 2em;
  margin-bottom: 1em;
  color: #202124;
  text-transform: uppercase;
  text-decoration: none;
}
.message {
  color: #000000 !important;
  text-align: center !important;
  margin: 20px 0 !important;
}
.modal {
  display: block;

  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  box-sizing: border-box;
  padding: 30px 10px;
}
.lightButton {
  color: #9d9d9d !important;
  text-align: center;
  margin-top: 8px;
  cursor: pointer;
  &:hover {
    color: #000000 !important;
  }
}
.input-hide {
  display: none !important;
  transition: all 1s ease-out;
}
.inputLast {
  margin-bottom: 16px !important;
}
.toHome {
  text-decoration: none;
}
.item-remove {
  position: absolute;
  top: 12px;
  right: 12px;
  text-decoration: none;
  &:hover {
    color: red;
  }
}
.close {
  color: #798493;
  &:hover {
    color: red;
  }
}
