

@font-face {
    font-family: "SegoeUI";
    src: url("fonts/SegoeUI-SemiBold.ttf"),
         url("fonts/SegoeUI-SemiBold.woff"),
         url("fonts/SegoeUI-SemiBold.eot");
    font-weight: $semi-bold;
   }

@font-face {
    font-family: "SegoeUI";
    src: url("fonts/SegoeUI.ttf"),
         url("fonts/SegoeUI.woff"),
         url("fonts/SegoeUI.eot");
    font-weight: $normal;
   }

@font-face {
    font-family: "SegoeUI";
    src: url("fonts/SegoeUI-Bold.ttf"),
         url("fonts/SegoeUI-Bold.woff"),
         url("fonts/SegoeUI-Bold.eot");
    font-weight: $bold;
   }   
@font-face {
    font-family: "SegoeUI";
    src: url("fonts/SegoeUI-Light.ttf"),
         url("fonts/SegoeUI-Light.woff"),
         url("fonts/SegoeUI-Light.eot");
    font-weight: $light;
   }   
