.label {
	display: inline-block;
	box-sizing: border-box;
	margin: 0 1px;
	font-family: SegoeUI;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: normal;
	text-align: left;
	color: #ffffff;
	border-radius: 3px;
	background-color: #66a3e9;
	padding: 3px 5px 5px;
	text-transform: lowercase;

	&--green {
		background-color: #39b7aa;
	}
	&--red {
		background-color: #fa6980;
	}
	svg {
		line-height: 1;
		font-size: 100%;
	}
}
