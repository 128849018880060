@import "../../assets/scss/init.scss";
.like {
	position: absolute;
	top: 6px;
	left: 8px;
	z-index: 1;
	font-size: 24px;
	text-decoration: none;

	transition: 0.3s;
	input {
		display: none;
	}

	i {
		font-family: "icons";
		cursor: pointer;
		transition: 0.3s;
		color: white;
	}

	svg {
		cursor: pointer;
		transition: 0.3s;
		color: white;
		&.like--on {
			transition: 0.3s;
			color: red;
		}
	}

	i.like--on {
		transition: 0.3s;
		color: red;
	}

	&:hover {
		transform: scale(1.2);
	}
}
@media (max-width:#{$mobile_max}px) {
	.like {
		left: 12px;
		padding: 5px;
		top: 0;
	}
}
