@import "../../assets/scss/init";
// @import "../../assets/scss/vars";
// @import "../../assets/scss/theme";


.label {
  display: block;
  box-sizing: border-box;
  padding: 0 20px;
  border-radius: 6px;
  border: solid 1px #d4d8e1;
  background-color: #ffffff;
  font-family: SegoeUI;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  // color: #707070;s
  display: flex;
  align-items: center;
i {
  color: #707070;

}
  b,
  em {
    font-weight: $bold;
    padding: 20px 20px 20px 10px;
    width: 80%;
		height: 100%;
  // color: #707070;
		
  }
  span {
    display: inline-block;
    margin-top: 20px;
		margin-bottom: 20px;
  color: #707070;
		
	}
	&:hover{
		border: 1px solid currentColor;
	}
}
.labelInput{
  box-sizing: border-box;
  padding: 0 20px;
  border-radius: 6px;
  border: solid 1px currentColor;
  background-color: #ffffff;
	font-family: SegoeUI;
	// color: currentColor;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  display: flex;
  align-items: center;
i {
  color: #707070;
	
}
  b,
  em {
    font-weight: $bold;
    padding: 20px 20px 20px 10px;
    width: 80%;
    height: 100%;
  }
  span {
		display: inline-block;
  color: #707070;
		
    margin-top: 20px;
    margin-bottom: 20px;
	}
}

.button {
  justify-self: flex-end;
  margin-right: 5px;
  margin-bottom: 5px;
  text-transform: uppercase;
	border-radius: 4px;
	color: currentColor;
  border: solid 1px currentColor;
  background-color: transparent;
  font-family: SegoeUI;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  // color: #798493;
  padding: 12px 24px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  transition: box-shadow 0.3s;
  &:hover {
		// box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
		background-color: currentColor;
		.btnSpan {
			transition: 0.3s;
			color: #ffffff;
		}
  }
  &--on {
    object-fit: contain;
    border-radius: 4px;
    background-color: #798493;
    font-family: SegoeUI;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    // color: #dfdfdf;
	}
	 .btnSpan {
       
        text-transform: uppercase;
				margin: 0;
				color: currentColor;
      }
}

.field {
  background-color: #ffffff;
  font-family: SegoeUI;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  margin-left: 12px;
  margin-right: 12px;
  color: #707070;
  border: none;
  flex-grow: 2;
  border-bottom: solid 1px #d4d8e1;
  &:focus {
    outline: none;
  }
}

.input-group {
  margin: 0 auto;
  padding: 40px 10px;
  max-width: 1160px;
}

.container {
  display: block;
  box-sizing: border-box;

  background: #fff;
}
.searchInput {
  width: 80%;
	cursor: pointer;
  color: #707070;
	
}

@media (max-width: #{$mobile_max}px) {
  .label {
    display: block;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 6px;
    border: solid 1px #d4d8e1;
    background-color: #ffffff;
    font-family: SegoeUI;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color:currentColor;

    b,
    em {
      font-weight: $bold;
    }
	}
	.labelInput{
  box-sizing: border-box;
  padding: 0 20px;
  border-radius: 6px;
  border: solid 1px currentColor;
  background-color: #ffffff;
	font-family: SegoeUI;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
	display: flex;
	flex-wrap: wrap;
  align-items: center;
i {
  color: #707070;
	
}
  b,
  em {
    font-weight: $bold;
    padding: 20px 20px 20px 10px;
    width: 80%;
    height: 100%;
  }
  span {
		display: block;
	color: #707070;
	width: 50%;
	
		margin-left: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
	}
}

  .button {
    margin-right: 5px;
    margin-bottom: 15px;
    margin-top: 15px;
    border-radius: 4px;
    border: solid 1px currentColor;
    background-color: transparent;
    font-family: SegoeUI;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: currentColor;
    padding: 12px 24px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    transition: box-shadow 0.3s;
    &:hover {
			background-color: currentColor;
			.btnSpan {
				color: #ffffff;
				transition: 0.3s;

			}
		}
    &--on {
      object-fit: contain;
      border-radius: 4px;
      background-color: #798493;
      font-family: SegoeUI;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: #dfdfdf;
    }
  }

  .input-group {
    margin: 0 auto;
    padding: 20px 10px;
    max-width: auto;
  }

  .container {
    display: block;
    box-sizing: border-box;
    background: #fff;
	}
	.field {
		margin-left: 0px;
	}
}
