@import "../../assets/scss/init";
//emergency style
.emergency {
  background-color: currentColor;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  padding-right: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.emergency__text {
  color: #ffffff;
  text-align: center;
  padding: 20px;
}
.emergency__close-button {
  position: absolute;
  font-size: 24px;
  font-weight: 300;
  top: 15px;
  right: 30px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 0px 6px 0px 0px;
  &:hover {
    color: #aeaeae;
  }
}
.emergency--hide {
  max-height: 0;
}
.emergency__text--none {
  opacity: 0;
}
.container,
.panel {
  display: flex;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 10px 10px;
  border-radius: 6px;
}

.panel {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
}

.fieldset {
  margin-top: 20px;
  margin-bottom: 6px;
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
}

.cb-group {
  width: 100%;
  max-width: 600px;
  margin-top: 6px;
  margin-bottom: 6px;
  text-align: left;
  user-select: none;
}

.input {
  margin: 5px;
  width: calc(49% - 10px);
}

.radio {
  &-input {
    display: none;
  }
  &-label {
    display: block;
    margin-top: 9px;
    margin-bottom: 9px;
    cursor: pointer;
    span {
      font-family: SegoeUI;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #717171;
      margin-top: 12px;
      margin-bottom: 6px;
    }
    &:before {
      position: relative;
      display: inline-block;
      margin-right: 8px;
      height: 10px;
      width: 10px;
      content: "";
      border-radius: 12px;
      border: 2px solid currentColor;
    }
    &--selected:before {
      background: currentColor;
    }
  }
}
.formButton {
  height: 40px;
  background-color: inherit;
  display: block;
  box-sizing: border-box;
  color: currentColor;
  outline: none;
  border: 1px solid currentColor;
  padding: 2px 10px 2px 10px;
  border-radius: 6px;
  text-transform: uppercase;
  font-family: SegoeUI;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  opacity: 0.8;
  text-decoration: none;
  flex-grow: 1;
  margin: 0 auto;
  &:hover {
    background-color: currentColor;
    span {
      color: #ffffff;
    }
  }
}
.centerPanel {
  display: block;
  margin: 0 auto;
  max-width: 600px;
}
@media (max-width:#{$mobile_max}px) {
  .input {
    margin: 5px;
    width: 100%;
  }
}
