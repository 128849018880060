@import "../../assets/scss/init";

@media (min-width:#{$tablet-max+1}px) {
	.main {
		width: 100%;
		display: flex;
		flex-direction: row;
		margin-bottom: 24px;
		overflow: hidden;
		border-radius: 6px;
	}

	.gallery {
		width: 66%;
		height: 60vw;
		max-height: 565px;
		overflow: hidden;
		border-radius: 6px;
	}

	.gallery--visible {
		width: 66%;
		height: 60vw;
		max-height: 565px;
		overflow: hidden;
		border-radius: 6px;
	}
	.data {
		width: 33%;
		background: #fff;
		padding: 10px 18px;
	}

	.title {
		font-size: 24px;
		color: #000;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.main-review {
		display: flex;
		flex-direction: row;
		padding-top: 28px;
		padding-bottom: 28px;
		img {
			width: 32px;
			height: 32px;
			border-radius: 32px;
			display: block;
			box-sizing: border-box;
			margin-right: 26px;
		}
		p {
			font-size: 11px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 15px;
			letter-spacing: normal;
			text-align: left;
			color: #707070;
		}

		label {
			margin-right: 16px;
			display: inline-block;
		}
		h6 {
			font-size: 16px;
			font-weight: 600;
			color: #4e5255;
			margin-bottom: 11px;
		}
	}
	.review-button {
		display: inline-block;
		border-radius: 6px;
		margin-top: 26px;
		outline: none;
		border-width: 1px;
		border-style: solid;
		padding-top: 10px;
		padding-bottom: 10px;
		font-family: inherit;
		width: 125px;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		line-height: 13px;
		text-transform: uppercase;
		text-align: center;

		&-more {
			margin-right: 17px;
			margin-left: 56px;
			border-color: rgba(0, 0, 0, 0.4);
			color: rgba(0, 0, 0, 0.4);
			background-color: #fff;
			transition: 0.5s;
			&:hover {
				border-color: currentColor;
				color: currentColor;
			}
		}
		&-post {
			border-color: currentColor;
			color: currentColor;
			background-color: #fff;
			transition: 0.5s;
			&:hover {
				background-color: currentColor;
				span {
					color: #fff;
				}
			}
		}
	}

	.location {
		position: relative;
		margin-top: 12px;
		padding-left: 30px;
		box-sizing: border-box;
		font-weight: $normal;
		cursor: pointer;
		&--on {
			font-weight: $bold;
		}
		i,
		svg {
			position: absolute;
			transition: 0.3s;
			top: 2px;
			left: 2px;
			font-size: 20px;
		}

		a {
			display: inline;
			border-bottom: 1px dashed currentColor;
			text-decoration: none;
			font-family: SegoeUI;
			font-size: 14px;

			font-stretch: normal;
			font-style: normal;
			line-height: 1.31;
			letter-spacing: normal;
			text-align: left;
			color: #666;
		}

		&:hover {
			i,
			svg {
				transition: 0.3s;
				top: -3px;
			}
		}
	}
	.services {
		margin-top: 16px;
		box-sizing: border-box;
		padding-top: 16px;
		padding-left: 10px;
		border-top: 1px solid #e9e9e9;
		padding-bottom: 16px;
		border-bottom: 1px solid #e9e9e9;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		li {
			width: 49%;
			font-family: SegoeUI;
			font-size: 14px;
			font-weight: $normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.25;
			letter-spacing: normal;
			text-align: left;
			color: #707070;
			padding-left: 16px;
			box-sizing: border-box;
			position: relative;
			i,
			svg {
				left: -10px;
				top: 2px;
				position: absolute;
				margin-right: 10px;
			}
			svg {
				top: -2px;
			}
			margin-bottom: 8px;
		}

		&__more {
			color: #3296cb;
		}
	}

	.products {
		margin: 24px 0;
	}

	.text {
		margin: 2px 0;
		background: #fff;
		box-sizing: border-box;
		padding: 33px 90px;
		max-width: 100vw;
		overflow-x: hidden;

		&-title {
			text-transform: uppercase;
			font-family: SegoeUI;
			font-size: 24px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			text-align: left;
			color: #202124;
			margin-bottom: 24px;
		}

		&-content {
			font-family: SegoeUI;
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.85;
			letter-spacing: normal;
			text-align: left;
			color: #707070;
			a {
				color: #68bce5;
			}
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-family: SegoeUI;
				font-size: 16px;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.31;
				letter-spacing: normal;
				text-align: left;
				color: #5e687a;
			}

			b,
			em {
				font-size: 125%;
				color: #68bce5;
			}
			ul {
				padding-left: 20px;
				list-style-type: disc;
			}
			img {
				max-width: 100%;
				height: auto;
			}
			button {
				height: 40px;
				color: #68bce5 !important;
				max-width: 50%;
				background-color: inherit;
				display: inline-block;
				box-sizing: border-box;

				outline: none;
				border: 1px solid currentColor;
				padding: 2px 10px 2px 10px;
				border-radius: 6px;
				text-transform: uppercase;
				font-family: SegoeUI;
				font-size: 12px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				letter-spacing: normal;
				text-align: center;
				cursor: pointer;
				transition: 0.3s;
				opacity: 0.8;
				text-decoration: none;
				flex-grow: 1;
				margin: 8px 0;
				strong {
					color: #68bce5 !important;
				}
				&:hover {
					background-color: #68bce5;
					strong {
						color: #fff !important;
					}
				}
			}
			table {
				max-width: 100%;
				tr {
					&:first-child {
						td {
							p {
								font-weight: 700;
							}
						}
					}
				}
				td {
					padding: 5px;
				}
			}
		}
	}
}

@media (min-width:#{$mobile-max+1}px) and (max-width:#{$tablet-max}px) {
	.main {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;
		overflow: hidden;
		border-radius: 6px;
	}

	.gallery {
		width: 100%;
		height: 100%;
		max-height: auto;
		overflow: hidden;
		border-radius: 6px;
	}
	.gallery--visible {
		width: 100%;
		height: 60vw;
		max-height: 565px;
		overflow: hidden;
		border-radius: 6px;
	}

	.data {
		width: 100%;
		background: #fff;
		padding: 10px 18px;
	}

	.title {
		font-size: 24px;
		color: #000;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.main-review {
		display: flex;
		flex-direction: row;
		padding-top: 28px;
		padding-bottom: 28px;
		img {
			width: 32px;
			height: 32px;
			border-radius: 32px;
			display: block;
			box-sizing: border-box;
			margin-right: 26px;
		}
		p {
			font-size: 11px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 15px;
			letter-spacing: normal;
			text-align: left;
			color: #707070;
		}

		label {
			margin-right: 16px;
			display: inline-block;
		}
		h6 {
			font-size: 16px;
			font-weight: 600;
			color: #4e5255;
			margin-bottom: 11px;
		}
	}

	.location {
		cursor: pointer;
		position: relative;
		margin-top: 12px;
		padding-left: 30px;
		box-sizing: border-box;
		font-weight: $normal;
		i,
		svg {
			position: absolute;
			transition: 0.3s;
			top: 2px;
			left: 2px;
			font-size: 20px;
		}

		a {
			cursor: pointer;
			display: inline;
			border-bottom: 1px dashed currentColor;
			text-decoration: none;
			font-family: SegoeUI;
			font-size: 14px;

			font-stretch: normal;
			font-style: normal;
			line-height: 1.31;
			letter-spacing: normal;
			text-align: left;
			color: #666;
		}

		&--on {
			font-weight: $bold;
		}
		&:hover {
			i,
			svg {
				transition: 0.3s;
				top: -3px;
			}
		}
	}
	.services {
		margin-top: 16px;
		box-sizing: border-box;
		padding-top: 16px;
		border-top: 1px solid #e9e9e9;
		padding-bottom: 16px;
		border-bottom: 1px solid #e9e9e9;
		display: flex;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		li {
			width: 24.5%;
			font-family: SegoeUI;
			font-size: 14px;
			font-weight: $normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.25;
			letter-spacing: normal;
			text-align: left;
			color: #707070;
			position: relative;
			i,
			svg {
				left: -20px;
				top: 2px;
				position: absolute;
			}
			svg {
				top: -2px;
			}
			margin-bottom: 8px;
		}

		&__more {
			color: #3296cb;
		}
	}

	.products {
		margin: 24px 0;
	}

	.text {
		margin: 2px 0;
		background: #fff;
		box-sizing: border-box;
		padding: 33px 90px;
		max-width: 100vw;
		overflow-x: hidden;

		&-title {
			text-transform: uppercase;
			font-family: SegoeUI;
			font-size: 24px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			text-align: left;
			color: #202124;
			margin-bottom: 24px;
		}

		&-content {
			font-family: SegoeUI;
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.85;
			letter-spacing: normal;
			text-align: left;
			color: #707070;
			a {
				color: #68bce5;
			}
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-family: SegoeUI;
				font-size: 16px;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.31;
				letter-spacing: normal;
				text-align: left;
				color: #5e687a;
			}

			b,
			em {
				font-size: 125%;
				color: #68bce5;
			}
			ul {
				padding-left: 20px;
				list-style-type: disc;
			}
			img {
				max-width: 100%;
				height: auto;
			}
			button {
				height: 40px;
				color: #68bce5 !important;
				max-width: 50%;
				background-color: inherit;
				display: inline-block;
				box-sizing: border-box;

				outline: none;
				border: 1px solid currentColor;
				padding: 2px 10px 2px 10px;
				border-radius: 6px;
				text-transform: uppercase;
				font-family: SegoeUI;
				font-size: 12px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				letter-spacing: normal;
				text-align: center;
				cursor: pointer;
				transition: 0.3s;
				opacity: 0.8;
				text-decoration: none;
				flex-grow: 1;
				margin: 8px 0;
				strong {
					color: #68bce5 !important;
				}
				&:hover {
					background-color: #68bce5;
					strong {
						color: #fff !important;
					}
				}
			}
			table {
				max-width: 100%;
				tr {
					&:first-child {
						td {
							p {
								font-weight: 700;
							}
						}
					}
				}
				td {
					padding: 5px;
				}
			}
		}
	}
}

@media (max-width:#{$mobile-max}px) {
	.main {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;
		overflow: hidden;
		border-radius: 6px;
	}

	.gallery {
		width: 100%;
		height: auto;
		max-height: auto;
		overflow: hidden;
		border-radius: 6px;
	}
	.gallery--visible {
		width: 100%;
		height: 60vw;
		max-height: 565px;
		overflow: hidden;
		border-radius: 6px;
	}
	.data {
		width: 100%;
		background: #fff;
		padding: 10px 18px;
	}

	.title {
		font-size: 24px;
		color: #000;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.main-review {
		display: flex;
		flex-direction: row;
		padding-top: 28px;
		padding-bottom: 28px;
		img {
			width: 32px;
			height: 32px;
			border-radius: 32px;
			display: block;
			box-sizing: border-box;
			margin-right: 26px;
		}
		p {
			font-size: 11px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 15px;
			letter-spacing: normal;
			text-align: left;
			color: #707070;
		}

		label {
			margin-right: 16px;
			display: inline-block;
		}
		h6 {
			font-size: 16px;
			font-weight: 600;
			color: #4e5255;
			margin-bottom: 11px;
		}
	}

	.location {
		position: relative;
		margin-top: 12px;
		padding-left: 30px;
		box-sizing: border-box;
		i,
		svg {
			position: absolute;
			transition: 0.3s;
			top: 2px;
			left: 2px;
			font-size: 20px;
		}

		a {
			display: inline;
			border-bottom: 1px dashed currentColor;
			text-decoration: none;
			font-family: SegoeUI;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.31;
			letter-spacing: normal;
			text-align: left;
			color: #666;
		}

		&:hover {
			i,
			svg {
				transition: 0.3s;
				top: -3px;
			}
		}
	}
	.services {
		margin-top: 16px;
		box-sizing: border-box;
		padding-top: 16px;
		border-top: 1px solid #e9e9e9;
		padding-bottom: 16px;
		border-bottom: 1px solid #e9e9e9;
		li {
			font-family: SegoeUI;
			font-size: 14px;
			font-weight: $normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.25;
			letter-spacing: normal;
			text-align: left;
			color: #707070;
			padding-left: 16px;
			box-sizing: border-box;
			position: relative;
			i,
			svg {
				left: -10px;
				top: 2px;
				position: absolute;
				margin-right: 10px;
			}
			svg {
				top: -2px;
			}
			margin-bottom: 8px;
		}

		&__more {
			color: #3296cb;
		}
	}

	.products {
		margin: 24px 0;
	}

	.text {
		margin: 2px 0;
		background: #fff;
		box-sizing: border-box;
		padding: 46px 16px;
		max-width: 100vw;
		overflow-x: hidden;

		&-title {
			text-transform: uppercase;
			font-family: SegoeUI;
			font-size: 24px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			text-align: left;
			color: #202124;
			margin-bottom: 24px;
		}

		&-content {
			font-family: SegoeUI;
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.85;
			letter-spacing: normal;
			text-align: left;
			color: #707070;

			a {
				color: #68bce5;
			}
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-family: SegoeUI;
				font-size: 16px;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.31;
				letter-spacing: normal;
				text-align: left;
				color: #5e687a;
			}

			b,
			em {
				font-size: 125%;
				color: #68bce5;
			}
			ul {
				padding-left: 20px;
				list-style-type: disc;
			}
			iframe {
				width: 100%;
			}
			img {
				max-width: 100%;
				height: auto;
			}
			button {
				height: auto;
				color: #68bce5 !important;
				max-width: 50%;
				background-color: inherit;
				display: inline-block;
				box-sizing: border-box;

				outline: none;
				border: 1px solid currentColor;
				padding: 2px 10px 2px 10px;
				border-radius: 6px;
				text-transform: uppercase;
				font-family: SegoeUI;
				font-size: 12px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				letter-spacing: normal;
				text-align: center;
				cursor: pointer;
				transition: 0.3s;
				opacity: 0.8;
				text-decoration: none;
				flex-grow: 1;
				margin: 8px 0;
				strong {
					color: #68bce5 !important;
				}
			}
			table {
				max-width: 100%;
				tr {
					&:first-child {
						td {
							p {
								font-weight: 700;
							}
						}
					}
				}
				td {
					padding: 5px;
				}
			}
		}
	}
}
@media (max-width: 450px) {
	.text-content {
		table {
			overflow-x: auto;
		}
	}
}
