@import "../../assets/scss/vars";
@import "../../assets/scss/init";
.footer-app-text {
  max-width: 100px;
}
.footer-app {
  display: flex;
  justify-content: flex-end;
  a {
    padding-left: 11px;
    svg {
      cursor: pointer;
    }
  }
  span {
    font-size: 12px;
    max-width: 137px;
    text-align: left;
    padding-left: 11px;
    font-weight: 600;
    color: #616161;
  }
}
.footer {
  color: #cbd0d3;
  background-color: #fff;
  font-size: 13px;
  margin-top: 50px;
}

.container {
  max-width: 1160px;
  margin: 0 auto;
  color: currentColor;
}
.col {
  box-sizing: border-box;
  padding: 52px 0;
  display: inline-flex;
  width: 49%;
  flex-direction: row;
  justify-content: flex-start;

  &:nth-of-type(2) {
    justify-content: space-between;
  }
}

.sub-col {
  display: block;
  padding-right: 30px;
  max-width: 100px;

  &-big {
    width: 50%;
    max-width: 250px;
  }
}

.input {
  border: 1px solid #cbd0d3;
  color: #cbd0d3;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  border-radius: 6px;
  display: block;
  padding: 6px;
  outline: none;
  margin-bottom: 6px;
  &:focus {
    border: 1px solid #cbd0d3;
  }

  &-btn {
    position: absolute;
    right: -16px;
    font-size: 20px;
    top: 4px;
    cursor: pointer;
    color: #cbd0d3;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    outline: none;
  }
}

.socials {
  font-size: 18px;
  li {
    display: inline-block;
  }
}

.hint {
  color: #cbd0d3;
  font-size: 11px;
}

.footer a {
  display: block;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: left;
  color: #616161;
  text-decoration: none;
}
.logo {
  text-align: left;
  font-size: 14px;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  padding: 19px;
  display: flex;
  color: currentColor;
  justify-content: space-between;
  a {
    display: inline;
    cursor: pointer;
    text-decoration: none;
    span {
      color: $ut-black;
    }
  }
}
.linkBottom {
  color: currentColor;
  text-decoration: underline !important;
}

.form-adress {
  display: flex;
  font-size: 11px;
  padding-top: 50px;
  margin-top: 70px !important;
  justify-content: space-between;
  max-width: 997px !important;
  h3 {
    text-transform: uppercase;
    color: currentColor;
    font-size: 20px;
    margin-bottom: 20px;
  }
}
.form-container {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  // flex: 0 0 50%;
  // max-width: 100%;
  width: 100%;
  &-form {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    align-items: center;
  }
  &-label {
    margin-bottom: 8px;
    margin-bottom: 20px;
    width: 100%;
    // padding: 0px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
  }
  &-lisence {
    display: flex;
    color: currentColor;
    a {
      color: currentColor;
    }
  }
  &-fields {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &-wrapper {
    display: flex;
    margin-bottom: 20px;
  }
  span {
    display: block;
    margin-bottom: 8px;
    color: #cbd0d3;
    align-self: flex-start;
  }

  input {
    width: 100%;
    padding: 10px 20px 10px 10px;
  }
  textarea {
    width: 100%;
    padding: 10px 20px 20px 10px;
  }
  &-icon {
    position: absolute;
    top: 35px;
    right: 10px;
    font-size: 1.2rem !important;
  }

  &-link {
    color: #cbd0d3 !important;
  }
  &-send {
    display: flex !important;
    justify-content: center;
    margin-right: 5px;
    border-radius: 6px;
    font-family: SegoeUI;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.82;
    letter-spacing: normal;
    text-align: center;
    text-decoration: none;
    transition: 0.3s;
    padding: 11px 32px;
    background-color: currentColor;
    text-transform: uppercase;
    width: 70px;
    margin: 0 auto;
    span {
      color: #fff;
      margin: 0px;
    }
  }
}
.address-container {
  text-align: center;
  padding: 25px 15px;
  // flex: 0 0 50%;
  max-width: 50%;

  &-descript {
    color: #cbd0d3;
    margin-bottom: 40px;
    margin-top: 16px;
  }
  &-address {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    li {
      // display: block;
      padding: 25px 15px;
      color: currentColor;
      flex-grow: 1;
      // flex: 0 0 50%;
      max-width: 40%;
      // width: 100%;
      &:nth-child(odd) {
        border-right: 1px solid #cbd0d3;
      }
      &:nth-child(1) {
        border-bottom: 1px solid #cbd0d3;
      }
      &:nth-child(2) {
        border-bottom: 1px solid #cbd0d3;
      }
    }

    h4 {
      color: #cbd0d3;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 10px;
    }
    svg {
      margin-bottom: 30px;
      font-size: 44px;
    }
    a {
      text-align: center;
      color: currentColor;
      &:hover {
        h4 {
          text-decoration: none;
          margin-bottom: 8px;
        }
        // p {
        //   text-decoration: underline;
        // }
      }
    }
    p {
      color: #616161;
      &:hover {
        text-decoration: underline;
        line-height: 1;
      }
    }
  }
}

@media (max-width:#{$mobile-max}px) {
  .footer-app {
    justify-content: center;
  }
  .form-adress {
    justify-content: center;
    flex-wrap: wrap;
  }
  .form-container {
    order: 2;
  }
  .address-container {
    order: 1;
  }
  .input {
    border: 1px solid #cbd0d3;
    color: #cbd0d3;
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    border-radius: 6px;
    display: block;
    padding: 6px;
    outline: none;
    margin-bottom: 6px;
    &:focus {
      border: 1px solid #cbd0d3;
    }

    &-btn {
      position: absolute;
      right: -16px;
      font-size: 20px;
      top: 4px;
      cursor: pointer;
      color: #cbd0d3;
      background-color: rgba(0, 0, 0, 0);
      border: none;
      outline: none;
    }
  }

  .col {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 12px 6px;
    display: inline-flex;
    width: 100%;

    justify-content: center;
    &:nth-of-type(2) {
      justify-content: center;
    }
  }

  .sub-col {
    display: none;

    &-big {
      padding: 12px 0;
      width: auto;
      max-width: auto;
    }
  }
}

@media (min-width:#{$mobile-max+1}px) and (max-width:#{$tablet-max}px) {
  .sub-col-big {
    display: none;
  }

  .footer > div {
    text-align: center;
  }
}
@media (max-width: 580px) {
  .form-container {
    max-width: 80%;
  }
  .address-container {
    max-width: 80%;
  }
}
@media (max-width: 365px) {
  .form-container {
    padding: 0 0;
  }
}
@media (max-width:#{$mobile_small_max}px) {
  .form-container {
    max-width: 100%;
  }
  .address-container {
    max-width: 100%;
    &-address {
      ul {
        // align-items: center;
        flex-direction: column;
      }
      li {
        max-width: none;
        width: 280px;
        &:nth-child(odd) {
          border-right: none;
        }
        &:nth-child(3) {
          border-bottom: 1px solid #cbd0d3;
        }
      }
    }
  }
}
