


.v-desktop {
    @media (max-width:#{$tablet_max+1}px) {
        display:none !important;
    }    
}
.v-mobile {
    @media (min-width:#{$mobile_max+1}px) {
        display:none !important;
    }    
}
.v-tablet {
    @media (max-width:#{$mobile_max}px) {
        display:none !important;
    }    
    @media (min-width:#{$tablet_max+1}px) {
        display:none !important;
    }    
}