@import "../../assets/scss/init";
.text--open {
max-height: initial !important;
    overflow: initial !important;
	transition: overflow 1s linear 1s;
}
.showText{
		margin: 5px 0;
		height: 20%;
		font-weight: $normal;
		font-size: 14px;
		&:hover {
			.corner {
				margin-left: 10px;
			}
			.cornerOpen {
	
				margin-left: 10px;
			}
		}
}
	.corner {
		display: inline;
		position: absolute;
    width: 5px;
    height: 5px;
		background-color: transparent;
		border-top: 1px solid currentColor;
		border-right: 1px solid currentColor;
		top: 7px;
		transform: rotate(45deg);
		margin-left: 3px;
		transition: all 0.3s linear 0.3s;
}
	.cornerOpen {
		display: inline;
    position: absolute;
    width: 5px;
    height: 5px;
		background-color: transparent;
		border-top: 1px solid currentColor;
		border-right: 1px solid currentColor;
		top: 7px;
		transform: rotate(-45deg);
		margin-left: 5px;
		transition: all 0.3s linear 0.3s;
}

.whiteBack {
	background: #fff !important;
	height: auto;
	&:hover {
				h6 {
			color: currentColor;
		}
	}
}

.container {
  display: flex;
  background: #fff;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  transition: 0.3s;
  &:hover {
    transition: 0.3s;
		box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
		h6 {
			color: currentColor;
		}
  }
}

.image {
  height: 180px;
  width: auto;
}

.title {
  font-family: SegoeUI;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #4e5255;
  margin-bottom: 8px;
}
.description {
  flex-grow: 1;
  padding: 8px 22px;
}
.prices {
  padding: 8px 22px 20px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
	min-width: 180px;
	cursor: default;
	
}
.text {
  font-family: SegoeUI;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
	color: #707070;
	transition: overflow 1s linear 1s;
	cursor: default;
	max-height: 106px;
	overflow: hidden;
}

.more {
  font-family: SegoeUI;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #3296cb;
}

.price {
  align-self: flex-end;
  font-family: SegoeUI;
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.77;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  margin-top: 8px;
	margin-bottom: 8px;
	cursor: default;
	
  span {
    font-size: 20px;
    font-weight: $normal;
    color: #9a9a9a;
    text-decoration: line-through;
  }
}

.hint {
  align-self: flex-end;
  margin-bottom: 16px;
  font-family: SegoeUI;
  font-size: 12px;
  font-weight: $normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
	color: #c0c0c0;
	cursor: default;
	
}

.buttons {
  align-self: flex-end;
}

.btn {
  background-color: #fff;
  display: inline-block;
  box-sizing: border-box;
  color: currentColor;
  outline: none;
  border: 1px solid currentColor;
  padding: 11px 18px;
  border-radius: 3px;
  text-transform: uppercase;
  font-family: SegoeUI;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  opacity: 0.8;
  &:hover {
    transition: 0.3s;
    background-color: currentColor;
    color: #fff;
    span,
    i {
      transition: 0.3s;
      color: #fff;
    }
  }

  &-icon {
    background-color: currentColor;
    padding: 3px 7px;
    top: 9px;
    position: relative;
    font-size: 30px;
    line-height: 1;
    outline: none;
    border: none;
    transition: 0.3s;
    i,
    span {
      transition: 0.3s;
      color: #fff;
    }

    &:hover {
      transition: 0.3s;
      background-color: #fff;
      i,
      span {
        transition: 0.3s;
        color: currentColor;
      }
    }
  }
}

@media (max-width:#{$mobile-max}px) {
  .container {
    display: flex;
    background: #fff;
    width: 100%;
    border-radius: 6px;
    flex-flow: column;
  }

  .image {
    height: auto;
    width: 100%;
  }

  .title {
    font-family: SegoeUI;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #4e5255;
    margin-bottom: 8px;
  }
  .description {
    flex-grow: 1;
    padding: 8px 22px;
  }
  .prices {
    padding: 8px 22px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
		flex-direction: column;
	cursor: default;
		
  }
  .text {
    font-family: SegoeUI;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
		color: #707070;
	cursor: default;
			max-height: 106px;
	overflow: hidden;
  }

  .more {
    font-family: SegoeUI;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #3296cb;
  }

  .price {
    align-self: center;
    font-family: SegoeUI;
    font-size: 26px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.77;
    letter-spacing: normal;
    text-align: left;
    color: #666666;
    margin-top: 8px;
		margin-bottom: 8px;
	cursor: default;
		

    span {
      font-size: 20px;
      font-weight: $normal;
      color: #9a9a9a;
      text-decoration: line-through;
    }
  }

  .hint {
    align-self: center;
    margin-bottom: 16px;
    font-family: SegoeUI;
    font-size: 12px;
    font-weight: $normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
		color: #c0c0c0;
	cursor: default;
		
  }

  .buttons {
    align-self: center;
  }

  .btn {
    background-color: #fff;
    display: inline-block;
    box-sizing: border-box;
    color: currentColor;
    outline: none;
    border: 1px solid currentColor;
    padding: 11px 18px;
    border-radius: 3px;
    text-transform: uppercase;
    font-family: SegoeUI;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
    opacity: 0.8;
    &:hover {
      transition: 0.3s;
      background-color: currentColor;
      color: #fff;
      span,
      i {
        transition: 0.3s;
        color: #fff;
      }
    }

    &-icon {
      background-color: currentColor;
      padding: 3px 7px;
      top: 9px;
      position: relative;
      font-size: 30px;
      line-height: 1;
      outline: none;
      border: none;
      transition: 0.3s;
      i,
      span {
        transition: 0.3s;
        color: #fff;
      }

      &:hover {
        transition: 0.3s;
        background-color: #fff;
        i,
        span {
          transition: 0.3s;
          color: currentColor;
        }
      }
    }
  }
}
