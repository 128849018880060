@import "../../assets/scss/init";

.title {
    font-family: SegoeUI;
    font-size: 24px;
    font-weight: $semi-bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    margin-top: 2em;
    margin-bottom: 1em;
    color: #999;
    text-transform: none;
    text-decoration: none;

    span {
        margin-bottom: 24px;
        font-size: 64px;
        display: block;
    }
}

.text {
    font-family: SegoeUI;
    font-size: 16px;
    font-weight: $normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
    letter-spacing: normal;
    text-align: center;
    margin-top: 2em;
    margin-bottom: 1em;
    color: lighten(#202124, 20%);

    text-decoration: none;

    a {
        text-decoration: underline;
    }
}
.panel {
    margin-top: 100px;
    padding-top: 20px;
    padding-bottom: 40px;
}
