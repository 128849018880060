@import "../../../assets/scss/init";
@import "./Reviews.buttons.module.scss";

.container {
	padding: 30px 98px 30px;
	background: #fff;
	margin-top: 40px;
	margin-bottom: 40px;
}

.flex {
	display: flex;
}

.title {
	text-transform: uppercase;
	font-family: SegoeUI;
	font-size: 24px;
	font-weight: $normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: #202124;
	margin-bottom: 16px;
}
.empty {
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 27px;
	text-align: center;
	color: #5e687a;
	margin-top: 70px;
	margin-bottom: 40px;
	&-text {
		margin-bottom: 40px;
	}
}

.items {
	&-col {
		max-width: 66%;
		display: flex;
		flex-flow: column;
		justify-content: space-around;
	}
}

.item {
	padding: 40px 0;
	border-bottom: 5px solid #f6f8f9;
	display: flex;
	&:last-of-type {
		border-bottom: none;
	}
	&-img {
		display: block;
		margin-right: 24px;
		width: 60px;
		height: 60px;
		border-radius: 100%;
		box-sizing: border-box;
	}
	&-name {
		font-family: SegoeUI;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		text-align: left;
		color: #000000;
	}
	&-title {
		margin-top: 12px;
		margin-bottom: 12px;
		font-family: SegoeUI;
		font-size: 20px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.35;
		letter-spacing: normal;
		text-align: left;
		color: #5e687a;
	}
	&-text {
		font-family: SegoeUI;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.31;
		letter-spacing: normal;
		text-align: left;
		color: #707070;
	}
}

@media (max-width:#{$tablet_max}px) {
	.container {
		padding: 30px 30px 30px;
		background: #fff;
		margin-top: 20px;
		margin-bottom: 20px;
	}
}

@media (max-width:#{$mobile_max}px) {
	.container {
		padding: 20px;
		background: #fff;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.flex {
		display: flex;
		flex-flow: column;
	}
	.button {
		display: block;
		width: 100%;
		text-align: center;
	}

	.title {
		text-transform: uppercase;
		font-family: SegoeUI;
		font-size: 24px;
		font-weight: $normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		text-align: left;
		color: #202124;
		margin-bottom: 16px;
	}

	.items {
		&-col {
			order: 1;
			max-width: 100%;
			display: flex;
			flex-flow: column;
			justify-content: space-around;
		}
	}

	.item {
		padding: 40px 0;
		border-bottom: 5px solid #f6f8f9;
		display: flex;
		&:last-of-type {
			border-bottom: none;
		}
		&-img {
			width: 60px;
			height: 60px;
			border-radius: 100%;
			margin-right: 21px;
		}
		&-name {
			font-family: SegoeUI;
			font-size: 18px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			text-align: left;
			color: #000000;
		}
		&-title {
			margin-top: 12px;
			margin-bottom: 12px;
			font-family: SegoeUI;
			font-size: 20px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.35;
			letter-spacing: normal;
			text-align: left;
			color: #5e687a;
		}
		&-text {
			font-family: SegoeUI;
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.31;
			letter-spacing: normal;
			text-align: left;
			color: #707070;
		}
	}
}
