@import "../../assets/scss/init";
:root {
    --litepickerBgColor: #fff;

    --litepickerMonthHeaderTextColor: #333;
    --litepickerMonthButton: #9e9e9e;
    --litepickerMonthButtonHover: #2196f3;
    --litepickerMonthWidth: calc(var(--litepickerDayWidth) * 7); // 7 days
    --litepickerMonthWeekdayColor: #9e9e9e;

    --litepickerDayColor: #333;
    --litepickerDayColorHover: #2196f3;
    --litepickerDayIsTodayColor: #f44336;
    --litepickerDayIsInRange: #bbdefb;
    --litepickerDayIsLockedColor: #9e9e9e;
    --litepickerDayIsBookedColor: #9e9e9e;
    --litepickerDayIsStartColor: #fff;
    --litepickerDayIsStartBg: #2196f3;
    --litepickerDayIsEndColor: #fff;
    --litepickerDayIsEndBg: #2196f3;
    --litepickerDayWidth: 38px;

    --litepickerButtonCancelColor: #fff;
    --litepickerButtonCancelBg: #9e9e9e;
    --litepickerButtonApplyColor: #fff;
    --litepickerButtonApplyBg: #2196f3;
}
.show-week-numbers {
    --litepickerMonthWidth: calc(var(--litepickerDayWidth) * 8);
}

.litepicker.lp {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, sans-serif;
    font-size: 0.8em;
    display: none;
    .close {
        position: absolute;
        font-size: 24px;
        font-weight: $light;
        top: 10px;
        right: 15px;
        color: #798493;
        cursor: pointer;
        border-radius: 0px 6px 0px 0px;
        &:hover {
            color: red;
        }
    }
    // .close:before,
    // .close:after {
    //   position: absolute;
    //   content: " ";
    //   height: 25px;
    //   width: 3px;
    //   background-color: #798493;
    //   // border-radius: 2px;
    // }
    // .close:before {
    //   transform: rotate(45deg);
    // }
    // .close:after {
    //   transform: rotate(-45deg);
    // }
    .container {
        &__months {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            background-color: var(--litepickerBgColor);
            border-radius: 5px;
            padding: 30px;
            box-shadow: none;
            width: calc(
                var(--litepickerMonthWidth) + 10px
            ); // 10px is padding (left 5px, right: 5px)
            box-sizing: content-box;
            &.columns-2 {
                width: calc((var(--litepickerMonthWidth) * 2) + 68px);
            }

            &.columns-3 {
                width: calc((var(--litepickerMonthWidth) * 3) + 30px);
            }

            &.columns-4 {
                width: calc((var(--litepickerMonthWidth) * 4) + 40px);
            }

            &.split-view {
                .month-item {
                    &-header {
                        .button-previous-month,
                        .button-next-month {
                            visibility: visible;
                        }
                    }
                }
            }

            .month-item {
                padding: 5px;
                width: var(--litepickerMonthWidth);

                &-header {
                    display: flex;
                    justify-content: space-evenly;
                    font-weight: 500;
                    padding: 10px 5px;
                    text-align: center;
                    align-items: center;
                    text-transform: uppercase;
                    font-size: 16px;
                    color: var(--litepickerButtonApplyBg);

                    .button-previous-month,
                    .button-next-month {
                        visibility: hidden;
                        text-decoration: none;
                        color: var(--litepickerButtonApplyBg);
                        padding: 3px 5px;
                        border-radius: 3px;
                        transition: color 0.3s, border 0.3s;
                        cursor: default;

                        > svg,
                        > img {
                            fill: var(
                                --litepickerButtonApplyBg
                            ); //  --litepickerMonthButton
                            pointer-events: none;
                        }

                        &:hover {
                            color: var(--litepickerMonthButtonHover);

                            > svg {
                                fill: var(--litepickerMonthButtonHover);
                            }
                        }
                    }
                }

                &-weekdays-row {
                    display: flex;
                    justify-self: center;
                    justify-content: flex-start;
                    color: var(--litepickerMonthWeekdayColor);

                    > div {
                        padding: 5px 0;
                        font-size: 85%;
                        flex: 1;
                        width: var(--litepickerDayWidth);
                        display: flex;
                        justify-content: center;
                    }
                }

                &:first-child {
                    .button-previous-month {
                        visibility: visible;
                    }
                }

                &:last-child {
                    .button-next-month {
                        visibility: visible;
                    }
                }

                &.no-previous-month {
                    .button-previous-month {
                        visibility: hidden;
                    }
                }

                &.no-next-month {
                    .button-next-month {
                        visibility: hidden;
                    }
                }
            }
        }

        &__days {
            display: flex;
            flex-wrap: wrap;
            justify-self: center;
            justify-content: flex-start;
            text-align: center;

            > div,
            > a {
                padding: 10px 0;
                width: var(--litepickerDayWidth);
            }

            .day-item {
                color: var(--litepickerDayColor);
                text-align: center;
                text-decoration: none;
                border-radius: 3px;
                transition: color 0.3s, border 0.3s;
                cursor: default;

                &:hover {
                    color: var(--litepickerDayColorHover);
                    box-shadow: inset 0 0 0 1px var(--litepickerDayColorHover);
                }

                &.is-today {
                    color: var(--litepickerDayIsTodayColor);
                }

                &.is-locked {
                    color: var(--litepickerDayIsLockedColor);
                    pointer-events: none;

                    &:hover {
                        color: var(--litepickerDayIsLockedColor);
                        box-shadow: none;
                        cursor: default;
                    }
                }

                &.is-booked {
                    color: var(--litepickerDayIsBookedColor);
                    pointer-events: none;

                    &:hover {
                        color: var(--litepickerDayIsBookedColor);
                        box-shadow: none;
                        cursor: default;
                    }
                }

                &.is-in-range {
                    background-color: var(--litepickerDayIsInRange);
                    border-radius: 0;
                }

                &.is-start-date {
                    color: var(--litepickerDayIsStartColor);
                    background-color: var(--litepickerDayIsStartBg);
                    border-top-left-radius: 6px;
                    border-bottom-left-radius: 6px;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;

                    &.is-flipped {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                }

                &.is-end-date {
                    color: var(--litepickerDayIsEndColor);
                    background-color: var(--litepickerDayIsEndBg);
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border-top-right-radius: 6px;
                    border-bottom-right-radius: 6px;

                    &.is-flipped {
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }

                &.is-start-date.is-end-date {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }

            .week-number {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #9e9e9e;
                font-size: 85%;
            }
        }

        &__footer {
            text-align: right;
            padding: 10px 5px;
            margin: 0 5px;
            background-color: #fafafa;
            box-shadow: inset 0px 3px 3px 0px #ddd;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            .preview-date-range {
                margin-right: 10px;
                font-size: 90%;
            }

            .button-cancel {
                background-color: var(--litepickerButtonCancelBg);
                color: var(--litepickerButtonCancelColor);
                border: 0;
                padding: 3px 7px 4px;
                border-radius: 3px;

                > svg,
                > img {
                    pointer-events: none;
                }
            }

            .button-apply {
                background-color: var(--litepickerButtonApplyBg);
                color: var(--litepickerButtonApplyColor);
                border: 0;
                padding: 3px 7px 4px;
                border-radius: 3px;
                margin-left: 10px;
                margin-right: 10px;

                &:disabled {
                    opacity: 0.7;
                }

                > svg,
                > img {
                    pointer-events: none;
                }
            }
        }

        &__tooltip {
            position: absolute;
            margin-top: -4px;
            padding: 4px 8px;
            border-radius: 4px;
            background-color: #fff;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
            white-space: nowrap;
            font-size: 11px;
            pointer-events: none;
            visibility: hidden;

            &:before {
                position: absolute;
                bottom: -5px;
                left: calc(50% - 5px);
                border-top: 5px solid rgba(0, 0, 0, 0.12);
                border-right: 5px solid transparent;
                border-left: 5px solid transparent;
                content: "";
            }

            &:after {
                position: absolute;
                bottom: -4px;
                left: calc(50% - 4px);
                border-top: 4px solid #fff;
                border-right: 4px solid transparent;
                border-left: 4px solid transparent;
                content: "";
            }
        }
    }

    &-open {
        overflow: hidden;
    }

    &-backdrop {
        display: none;
        background-color: #000;
        opacity: 0.6;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
