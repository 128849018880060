/* font weight */
$light: 300;
$regular: 400;
$normal: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black: 900;

/* z-levels */
$video-bg-level: -1;
$home-panel-level: 1;
$header-level: 10;
$popup-level: 6;
$dropdown-level: 2;
$z-menu: 10;
$overlay-level: 20;

/*	Breakpoints */
$mobile_max: 767;
$mobile_small_max: 375;
$tablet_max: 1023;

/* classes */
%clear,
%clearfix {
    clear: both;
    &:after {
        content: "";
        visibility: hidden;
        display: block;
        height: 0;
        clear: both;
    }
}
