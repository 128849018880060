@import "../../../assets/scss/init";
.item-padding {
  padding: 10px 20px;
}
.message {
  margin-top: 18px;
  margin-bottom: 12px;
  font-weight: $normal;
  font-size: 16px;
}

.list {
  margin-left: -10px;
}

.item {
  background: #fff;
  width: calc(50% - 20px);
  display: inline-block;
  border-radius: 6px;
  box-sizing: border-box;
  margin: 10px;
  transition: box-shadow 0.3s;
  box-shadow: none;
  height: 180px;
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }

  &-title {
    margin: 10px 24px 10px 20px;

    font-family: SegoeUI;
    font-size: 20px;
    font-weight: $normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #4e5255;
    text-decoration: none;
  }

  &-remove {
    display: block;
    box-sizing: border-box;
    padding: 5px 10px;
    position: absolute;
    top: 4px;
    right: 4px;
    font-size: 24px;
    cursor: pointer;
    font-weight: 100;
    color: #999;
    transition: color 0.3s;

    &:hover {
      color: currentColor;
    }
  }

  &-text {
    margin: 10px 10px 10px 20px;

    font-family: SegoeUI;
    font-size: 14px;
    font-weight: $normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #4e5255;
    text-decoration: none;
  }

  &-link {
    text-decoration: none;
    display: flex;
    width: 100%;
    height: 100%;
  }

  &-img {
    /* top: 0;
        left: 0; */
    height: 100%;
    width: auto;
    max-width: 40%;
    object-fit: cover;
    border-radius: 6px 0 0 6px;
  }
}

@media (max-width: #{$tablet_max}px) {
  .item {
    width: 100%;
    margin: 10px 0;
  }
}
@media (max-width: #{$mobile_max}px) {
  .item {
    &-title {
      font-size: 16px;
    }
    &-text {
      font-size: 12px;
    }
  }
}
@media (max-width: #{$mobile_max}px) {
  .item-padding {
    overflow-x: hidden;
  }
}
