/**
* Rangeslider
*/
.rangeslider {
	margin: 20px 0;
	position: relative;
	background: #c4c4c4;
	-ms-touch-action: none;
	touch-action: none;
}
.rangeslider,
.rangeslider .rangeslider__fill {
	display: block;
}
.rangeslider .rangeslider__handle {
	background: #fff;
	border: 2px solid currentColor;
	cursor: pointer;
	display: inline-block;
	position: absolute;
}
.rangeslider .rangeslider__handle .rangeslider__active {
	opacity: 1;
}
.rangeslider .rangeslider__handle-tooltip {
	width: 40px;
	height: 40px;
	text-align: center;
	position: absolute;
	background-color: rgba(0, 0, 0, 0.8);
	font-weight: normal;
	font-size: 14px;
	transition: all 100ms ease-in;
	border-radius: 4px;
	display: inline-block;
	color: white;
	left: 50%;
	transform: translate3d(-50%, 0, 0);
}
.rangeslider .rangeslider__handle-tooltip span {
	margin-top: 12px;
	display: inline-block;
	line-height: 100%;
}
.rangeslider .rangeslider__handle-tooltip:after {
	content: " ";
	position: absolute;
	width: 0;
	height: 0;
}
/**
* Rangeslider - Horizontal slider
*/
.rangeslider-horizontal {
	height: 12px;
	border-radius: 10px;
}
.rangeslider-horizontal .rangeslider__fill {
	height: 100%;
	background-color: currentColor;
	border-radius: 10px;
	top: 0;
}
.rangeslider-horizontal .rangeslider__handle {
	width: 26px;
	height: 26px;
	border-radius: 26px;
	top: 50%;
	transform: translate3d(-50%, -50%, 0);
	box-shadow: 0px 1px 5px rgba(13, 68, 104, 0.27);
}
.rangeslider-horizontal .rangeslider__handle:focus {
	background: currentColor;
	outline: none;
}
.rangeslider-horizontal .rangeslider__handle:after {
	content: " ";
	position: absolute;
	width: 9px;
	height: 10px;
	top: 8px;
	left: 8px;
	border-left: 1px solid currentColor;
	border-right: 1px solid currentColor;
}
.rangeslider-horizontal .rangeslider__handle:before {
	content: " ";
	position: absolute;
	width: 1px;
	height: 10px;
	top: 8px;
	left: 13px;
	border-left: 1px solid currentColor;
}

.rangeslider-horizontal .rangeslider__handle:focus:before {
	border-left: 1px solid #fff;
}
.rangeslider-horizontal .rangeslider__handle:focus:after {
	border-left: 1px solid #fff;
	border-right: 1px solid #fff;
}

.rangeslider-horizontal .rangeslider__handle-tooltip {
	top: -55px;
}
.rangeslider-horizontal .rangeslider__handle-tooltip:after {
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 8px solid rgba(0, 0, 0, 0.8);
	left: 50%;
	bottom: -8px;
	transform: translate3d(-50%, 0, 0);
}

/**
* Rangeslider - Labels
*/
.rangeslider__labels {
	position: relative;
}
.rangeslider-vertical .rangeslider__labels {
	position: relative;
	list-style-type: none;
	margin: 0 0 0 24px;
	padding: 0;
	text-align: left;
	width: 250px;
	height: 100%;
	left: 10px;
}
.rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
	position: absolute;
	transform: translate3d(0, -50%, 0);
}
.rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
	content: "";
	width: 10px;
	height: 2px;
	background: black;
	position: absolute;
	left: -14px;
	top: 50%;
	transform: translateY(-50%);
	z-index: -1;
}
.rangeslider__labels .rangeslider__label-item {
	position: absolute;
	font-size: 14px;
	cursor: pointer;
	display: inline-block;
	top: 10px;
	transform: translate3d(-50%, 0, 0);
}
