$ut-rojo: rgb(250, 105, 128);
$ut-azul: rgb(50, 150, 203);
$ut-black: #202124;
$theme-color: desaturate($ut-rojo, 100%);

html,
body {
	font-family: SegoeUI, Sans-serif;
	font-size: 16px;
	font-weight: $semi-bold;
}

body {
	background-color: #f6f8f9;

	.ut-theme-color {
		color: $theme-color;
	}
	.ut-theme-bgcolor {
		background-color: $theme-color;
	}

	&.theme-rojo {
		$theme-color: $ut-rojo;
		.ut-theme-color {
			color: $theme-color;
		}
		.ut-theme-bgcolor {
			background-color: $theme-color;
		}
	}

	&.theme-azul {
		$theme-color: $ut-azul;
		.ut-theme-color {
			color: $theme-color;
		}
		.ut-theme-bgcolor {
			background-color: $theme-color;
		}
	}
}

.ut-main-container {
	min-height: calc(100vh - 200px);
	box-sizing: border-box;
	overflow-x: hidden;
}

.ut-brand {
	//font-size: inherit;
	font-family: inherit;
	text-decoration: none;
	text-transform: uppercase;
	font-weight: $bold;
	span {
		color: $ut-black;
	}
}
