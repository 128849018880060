@import '../../assets/scss/init';

.text {
    
        font-family: SegoeUI;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #717171;
       
}

.form {
    display:flex;
    align-items: center;
    flex-direction: column;
    border-radius: 6px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 30px 10px;
    margin-bottom:10px;
}



.fieldset {
    margin-top:20px;
    margin-bottom:6px;
    display: flex;
    flex-wrap: wrap;
    max-width: 600px;
}

.cb-group {
    width:100%;
    max-width: 600px;
    margin-top:6px;
    margin-bottom:6px;
    text-align: left;
    user-select:none;

}


.input {
    margin:5px;
    width:calc(49% - 10px);
}

@media (max-width:#{$mobile_max}px) {
 
    .input {
        margin:5px;
        width:100%;
    }

}