@import "../../assets/scss/init";
.prefixPrice {
	font-size: 12px;
	color: #666666;
}
.averagePrice {
	font-family: SegoeUI;
	font-size: 20px;
	font-weight: $semi-bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 0.9;
	letter-spacing: normal;
	color: #666666;
}
.container {
	opacity: 1;
	transition: opacity 0.3s;
	display: flex;
	justify-content: flex-start;
	width: 100%;
	flex-direction: row;
	margin-top: 33px;
	overflow: hidden;
}

.controls {
	position: absolute;
	top: 0;
	cursor: pointer;
	text-decoration: none;
	color: #202124;
	display: block;
	box-sizing: border-box;
	padding: 10px 12px;
	transform: scaleY(0.75);
	border-radius: 3px;
	transition: 0.3s;
	border: 1px solid rgba(0, 0, 0, 0);
	user-select: none;
	background: none;
	&:hover {
		transition: 0.3s;
		border: 1px solid currentColor;
	}
	&-left {
		right: 40px;
	}
	&-right {
		right: 0px;
	}

	&:disabled {
		cursor: default;
		color: lighten(#202124, 66%);
	}
}
.block {
	background-size: 100% auto;
	width: 31%;
	min-width: 236px;
	max-width: 370px;
	border-radius: 6px;
	margin-bottom: 10px;
	box-sizing: border-box;
	background-position: top center;
	background-repeat: no-repeat;
	transition: background-size 0.3s, box-shadow 0.3s;
	padding-top: 180px;
	&:nth-of-type(1) {
		margin-right: 3%;
	}
	&:nth-of-type(2) {
		margin-right: 3%;
	}
	&:hover {
		transition: 0.3s;
		background-size: 110% auto;
		box-shadow: 0 0px 10px rgba(0, 0, 0, 0.25);
		.item-socials {
			transform: translateX(0px);
			opacity: 1;
		}
		.block-price {
			background: currentColor;
			span {
				color: #ffffff;
			}
		}
	}

	&-img-link {
		display: block;
		position: absolute;
		top: 0px;
		left: 0;
		width: 100%;
		height: 180px;
	}

	&-data {
		position: relative;
		width: 100%;
		min-height: 270px;
		background-color: #fff;
		box-sizing: border-box;
		padding-top: 14px;
		padding-bottom: 33px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&-container {
		padding-left: 40px;
		padding-right: 20px;

		& > a {
			text-decoration: none;
		}
	}

	&-price {
		position: absolute;
		top: -50px;
		right: 8px;
		padding: 10px 16px 12px;
		border-radius: 20px;
		background-color: #ffffff;
		font-family: SegoeUI;
		font-size: 20px;
		font-weight: $semi-bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 0.9;
		letter-spacing: normal;
		text-align: right;
	}

	&-labels {
		position: absolute;
		top: -12px;
		left: 40px;
	}

	&-label {
		display: inline-block;
		box-sizing: border-box;
		font-family: SegoeUI;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.31;
		letter-spacing: normal;
		text-align: left;
		color: #ffffff;
		height: 17px;
		padding: 0 6px 3px;
		border-radius: 3px;
		background-color: #66a3e9;
		margin-right: 3px;
		&-green {
			background-color: #96af64;
		}
		&-teal {
			background-color: #39b7aa;
		}
		&-red {
			background-color: #fa6980;
		}
	}

	&-title {
		font-family: SegoeUI;
		font-size: 16px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		text-align: left;
		color: #202124;
		margin-bottom: 8px;
		margin-top: 8px;
	}

	&-tags {
		margin-bottom: 8px;
		margin-top: 8px;
		li {
			display: inline-block;
			padding: 0 5px;
			box-sizing: border-box;
			border-right: 1px solid currentColor;
			font-family: SegoeUI;
			font-size: 13px;
			font-weight: 300;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
			text-align: left;
			color: #a6adb4;
			&:last-of-type {
				border-right: none;
			}
		}
	}

	&-features {
		margin-bottom: 16px;
		//padding-left:20px;
		max-height: 68px;
		overflow-y: hidden;

		font-family: SegoeUI;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: normal;
		text-align: left;
		color: #202124;
		list-style-type: disc;
	}

	&-review {
		display: flex;
		flex-direction: row;
		img {
			border-radius: 100%;
			width: 40px;
			height: 40px;
		}
		p {
			font-family: SegoeUI;
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.31;
			letter-spacing: normal;
			text-align: left;
			color: #707070;
			margin-left: 9px;
		}
	}

	&-like {
		position: absolute;
		top: 6px;

		left: 8px;
		z-index: 1;
		font-size: 24px;
		text-decoration: none;
		color: #fff;

		transition: 0.3s;
		input {
			display: none;
		}
		i {
			font-family: "icons";
			cursor: pointer;
		}
		i:before {
			text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
			content: "\f10d";
		}

		input:checked + i {
			transition: 0.3s;
			color: red;
			&:before {
				content: "\f10e";
			}
		}

		&:hover {
			transform: scale(1.2);
		}
	}

	&-buttons {
		display: flex;
		padding: 0 20px;
		flex-direction: row;
		justify-content: center;
		align-items: flex-end;
	}

	&-btn {
		background-color: #fff;
		display: inline-block;
		box-sizing: border-box;
		color: currentColor;
		outline: none;
		border: 1px solid currentColor;
		padding: 11px 16px;
		border-radius: 3px;
		text-transform: uppercase;
		font-family: SegoeUI;
		font-size: 11px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		text-align: center;
		cursor: pointer;
		transition: 0.3s;
		opacity: 0.8;
		margin-left: 3px;
		margin-right: 3px;
		text-decoration: none;
		&:hover {
			transition: 0.3s;
			background-color: currentColor;
			color: #fff;
			span,
			i {
				transition: 0.3s;
				color: #fff;
			}
		}

		&-icon {
			background-color: currentColor;
			padding: 3px 7px;

			position: relative;
			font-size: 30px;
			line-height: 1;
			outline: none;
			border: none;
			transition: 0.3s;
			i,
			span {
				transition: 0.3s;
				color: #fff;
			}

			&:hover {
				transition: 0.3s;
				background-color: #fff;
				i,
				span {
					transition: 0.3s;
					color: currentColor;
				}
			}
		}
	}
}
.item-socials {
	transition: 0.5s;
	transform: translateX(-50px);
	position: absolute;
	top: 36px;
	left: 6px;
	z-index: 1;
	background: #f5f5f5;
	border-radius: 6px;
	// overflow: hidden;
	padding: 3px 0px;
	opacity: 0;
	li {
		// color: currentColor;
		&:first-child {
			display: none;
		}
		button {
			text-align: center;
			display: block;
			padding: 3px 8px !important;
			color: #c8cdd5 !important;
			text-decoration: none;
			font-size: 16px;
			outline: none;
			svg {
				color: #c8cdd5;
			}
		}
		a {
			text-align: center;
			display: block;
			padding: 3px 8px;
			color: #c8cdd5 !important;
			text-decoration: none;
			font-size: 16px;
			svg {
				color: #c8cdd5;
			}
		}
	}

	button {
		&:hover {
			color: currentColor !important;
		}
	}
}
@media (max-width:#{$tablet_max}px) {
	.block {
		background-size: 100% auto;
		width: 31%;
		min-width: 236px;
		max-width: 370px;
		border-radius: 6px;
		margin-bottom: 10px;
		box-sizing: border-box;
		background-position: top center;
		background-repeat: no-repeat;
		transition: background-size 0.3s, box-shadow 0.3s;
		padding-top: 180px;
		&:nth-of-type(1) {
			margin-right: 3%;
		}
		&:nth-of-type(2) {
			margin-right: 3%;
		}
		&:hover {
			transition: 0.3s;
			background-size: 110% auto;
			box-shadow: 0 0px 10px rgba(0, 0, 0, 0.25);
		}

		&-data {
			position: relative;
			width: 100%;
			min-height: 270px;
			background-color: #fff;
			box-sizing: border-box;
			padding-top: 14px;
			padding-bottom: 33px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&-container {
			padding-left: 20px;
			padding-right: 20px;

			& > a {
				text-decoration: none;
			}
		}
	}
}

@media (max-width:#{$mobile_max}px) {
	.displayBlock {
		display: block;
		position: absolute;
		top: 35px;
		left: 12px;
		z-index: 1;
		overflow: hidden;
		background: none;
		border-radius: 4px;
		background: #f5f5f5 !important;

		li {
			display: block;
			&:first-child {
				button {
					padding: 8px !important;
					background: #f5f5f5 !important;
					margin-bottom: 5px;
					border-radius: 6px;
					outline: none;
					border: none;
					color: currentColor;
					svg {
						font-size: 20px;
						display: flex;
						color: currentColor;
					}
				}
			}
			button {
				padding: 8px !important;
				background: #f5f5f5 !important;
				margin-bottom: 5px;
				border-radius: 6px;
				i {
					font-size: 18px;
					display: flex;
				}
			}
		}
	}
	.item-socials {
		display: block;
		position: absolute;
		top: 35px;
		left: 12px;
		z-index: 1;
		overflow: hidden;
		background: none;
		border-radius: 4px;
		transform: translateX(0px);
		opacity: 1;

		li {
			display: none;
			&:first-child {
				display: block;

				button {
					padding: 8px !important;
					background: #f5f5f5 !important;
					margin-bottom: 5px;
					border-radius: 6px;
					outline: none;
					border: none;
					color: currentColor;
					svg {
						font-size: 20px;
						display: flex;
						color: currentColor;
					}
				}
			}
		}
	}
	.block {
		background-size: auto 50%;
		background-position: 50% 50%;
		width: 48.5%;
		min-width: 200px;
		max-width: 100%;
		border-radius: 6px;
		margin-bottom: 10px;
		box-sizing: border-box;
		background-position: top center;
		background-repeat: no-repeat;
		transition: background-size 0.3s, box-shadow 0.3s;
		padding-top: 180px;
		&:nth-of-type(1) {
			margin-right: 2%;
		}

		&:hover {
			transition: 0.3s;
			background-size: auto 50%;
			box-shadow: 0 0px 10px rgba(0, 0, 0, 0.25);
		}

		&-data {
			position: relative;
			width: 100%;
			min-height: 270px;
			background-color: #fff;
			box-sizing: border-box;
			padding-top: 14px;
			padding-bottom: 33px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&-container {
			padding-left: 20px;
			padding-right: 20px;

			& > a {
				text-decoration: none;
			}
		}
	}
}

@media (max-width: 400px) {
	.block {
		background-size: auto 80%;
		background-position: 50% 50%;
		width: 100%;
		min-width: 236px;
		max-width: 100%;
		border-radius: 6px;
		margin-bottom: 10px;
		box-sizing: border-box;
		background-position: top center;
		background-repeat: no-repeat;
		transition: background-size 0.3s, box-shadow 0.3s;
		padding-top: 180px;
		&:nth-of-type(1) {
			margin-right: 2%;
		}

		&:hover {
			transition: 0.3s;
			background-size: auto 80%;
			box-shadow: 0 0px 10px rgba(0, 0, 0, 0.25);
		}

		&-data {
			position: relative;
			width: 100%;
			min-height: 270px;
			background-color: #fff;
			box-sizing: border-box;
			padding-top: 14px;
			padding-bottom: 33px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&-container {
			padding-left: 20px;
			padding-right: 20px;

			& > a {
				text-decoration: none;
			}
		}
	}
}
