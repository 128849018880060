@import '../../assets/scss/init';

$main-color: $ut-azul;
$secondary-color:desaturate($ut-azul,100%);
.loading {
    position: absolute;
    z-index: 9999;
    width:100%;
    height:100%;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity:0.9;
    overflow: hidden;
}

.logo:after {
    opacity:0.75;
    content:'U';
    display: block;
    font-family: SegoeUI, Sans-serif;
    color:$ut-azul;    
    font-size: 64px;
    font-weight: $normal;
    animation-duration: 3s;
    animation-name: slidein;
    
    animation-iteration-count:infinite;
    //animation-direction: normal, reverse;    
}
@keyframes slidein {
    0% {

    -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
    background-image: linear-gradient(
        to right,
        $secondary-color,
        $secondary-color 50%,
        $main-color 50%);
        background-size: 200% 100%;
        background-position: 100%;
}

 50% {
    
    background-position: 0%;
}
100% {

    -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
    background-image: linear-gradient(
        to right,
        $secondary-color,
        $secondary-color 50%,
        $main-color 50%);
        background-size: 200% 100%;
        background-position: 100%;
}
}
