@import "../../assets/scss/init";
@import "../../assets/scss/theme";

.container {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0px;
    box-sizing: border-box;
    z-index: $overlay-level;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    margin: 0px !important;
}
.mainContainer {
    width: 600px;
}

.close {
    font-size: 24px;
    position: absolute;
    font-weight: $light;
    top: 10px;
    right: 15px;
    color: #798493;

    cursor: pointer;

    border-radius: 0px 6px 0px 0px;
    &:hover {
        color: red;
    }
}
.wrapperClick {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: -1;
    cursor: pointer;
}
@media (max-width:#{$mobile_max}px) {
    .container > div {
        padding-top: 40px;
    }
    .mainContainer {
        width: 80%;
    }
    .close {
        right: 15px;

        top: 51px;
    }
}
@media (max-width:#{$mobile_small_max}px) {
    .mainContainer {
        width: 100%;
    }
}
