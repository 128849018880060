
.ut-banner {
    
    
    background-size:100% auto;
    background-position: 50% 50%;
    transition:0.3s;
    width:100%;
    height:395px;
    border-radius:6px;
    overflow: hidden;
    &:hover {
        transition:0.3s;
        background-size:110% auto;
    }
    &__like {
        position: absolute;
        top:12px;
        
        right:14px;
        z-index:1;
        font-size: 24px;
        text-decoration: none;
        color:#FFF;
       
        transition:0.3s;
        input {
            display: none;
        }
        i{
            font-family: "icons";
            cursor: pointer;
        }
        i:before {
            text-shadow: 0 0 5px rgba(0,0,0,.5);
            content:"\f10d";
        }
        
        input:checked + i {
           transition:0.3s;
           color:red; 
            &:before {
                content:"\f10e";
            }
        }
        
        &:hover {
            transform: scale(1.2);
        }
    }

    &__socials {
        transition: 0.5s;
        transform: translateX(50px);
        position:absolute;
        top:50px;
        right: 10px;
        z-index:1;
        background:#f5f5f5;
        border-radius:6px;
        overflow: hidden;
        
        padding:20px 0px;

        li {
            color:inherit;
            a{
                text-align: center;
                display: block;
                padding:8px 8px 6px;
                line-height:1;
                color:#c8cdd5;
                text-decoration:none;
                font-size: 16px;
            }
        }

        li:hover {
            background-color:currentColor;
            a {
                color:#FFF;
            }
        }
    }

    &:hover &__socials{
        transition: 0.5s;
        transform: translateX(0px);
    }

    &__data {
        background: rgba(0,0,0,.5);    
        width:300px;
        padding-top:40px;
        box-sizing: border-box;
        height:100%;

    }


    &__title {
        @extend %data-element;
        font-family: SegoeUI;
        font-size: 26px;
        font-weight: $bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.15;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
    }

    &__slogan {
        @extend %data-element;
        box-sizing: border-box;
        width:100%;
        font-family: SegoeUI;
        display: block;
        background-color: #fec231;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
    }

    &__text {
        @extend %data-element;
        font-family: SegoeUI;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
    }

    &__buttons {
        @extend %data-element;
    }

    &__button {
        
        display: inline-block;
        margin-right:5px;
        border-radius: 6px;
        font-family: SegoeUI;
        font-size: 14px;
        font-weight: $semi-bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.82;
        letter-spacing: normal;
        text-align: center;
        text-decoration: none;
        transition: 0.3s;
        padding:11px 32px;
        background-color:currentColor;
        text-transform:uppercase;
        i,span {
            color: #daeff9;
        }
        
        &:hover {
            transition: 0.3s;
            box-shadow: inset 0 0 10px rgba(0,0,0,.5);
        }
        &-icon {
            font-size:36px;
            line-height: 1;
            padding: 2px 6px;
            top:12px;

        }
    }

}

//Helper
%data-element {
    display: block;
    padding-left:33px;
    padding-right:20px;
    text-decoration: none;
    margin-top:16px;
    margin-bottom:16px;
}