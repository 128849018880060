.ut {
    &-form {
        &__container {
            padding: 10px;
        }
        &__group {
            margin-top:6px;
            margin-bottom:6px;
        }
        &__label {
            font-family: SegoeUI;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #717171;
            display: block;
            margin-top:12px;
            margin-bottom:6px;
        }
        &__input {

            display: block;
            width:100%;
            background:none;
            color: #707070;
            border:1px solid #d6d4d6;
            border-radius: 6px;
            box-sizing: border-box;
            padding:6px;
            font-family: SegoeUI;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: italic;
            line-height: 1.36;
            letter-spacing: normal;
            text-align: left;
        }
        
        &__button {
            background:#FFF;
            border-radius: 6px;
            border: solid 1px currentColor;
            padding:12px 16px;
            color:currentColor;
            text-transform: uppercase;
            display: block;
            margin: 12px auto;
            cursor: pointer;
            transition:background 0.5s;
            &:focus {
                outline:none;
            }
            &:hover {
                background:currentColor;
                transition:background 0.5s;
                span {
                    color:#FFF;
                }
            }
        }

        &__preloader {
            padding:12px 16px;
            display: flex;
            justify-content: center;
            text-align: center;
            margin: 12px auto;
        }
        
        &__button-inverse {
            background:currentColor;
            border-radius: 6px;
            border: solid 1px currentColor;
            padding:12px 16px;
            color:currentColor;
            text-transform: none;
            display: block;
            margin: 12px auto;
            cursor: pointer;
            transition:box-shadow 0.5s;
            box-shadow: none;
            font-size: 16px;
            span {
                color:#FFF;
            }
            &:focus {
                outline:none;
            }
            
            &:hover {
                transition:box-shadow 0.5s;
                box-shadow: 0 0 10px rgba(0,0,0,.5);
            }
        }
        &__button-wide {
            padding:12px 32px;
        }

    }
    
}